const documentationBD = [
  {
    id: 1,
    title: "Разрешение на строительство 120 квартал Литер 1",
    subtitle: "",
    company: 1,
    file: "files/NSK/razStrL1.zip",
  },
  {
    id: 2,
    title: "Разрешение на строительство 120 квартал Литер 2",
    subtitle: "",
    company: 1,
    file: "files/NSK/razStrL2.zip",
  },
  {
    id: 3,
    title: "Разрешение на ввод 120 квартал Литер 1",
    subtitle: "",
    company: 1,
    file: "files/NSK/razVvodL1.zip",
  },
  {
    id: 4,
    title: "Аудиторское заключение за 2016г",
    subtitle: "",
    company: 1,
    file: "files/NSK/audit2006.zip",
  },
  {
    id: 5,
    title: "Договор страхования 120 квартал Литер 1",
    subtitle: "",
    company: 1,
    file: "files/NSK/strahovL1.zip",
  },
  {
    id: 6,
    title: "Договор страхования 120 квартал Литер 2",
    subtitle: "",
    company: 1,
    file: "files/NSK/strahovL2.zip",
  },
  {
    id: 7,
    title: "Свидетельство о регистрации земельного участка 120 квартал Литер 1",
    subtitle: "",
    company: 1,
    file: "files/NSK/svid120kvL1.zip",
  },
  {
    id: 8,
    title: "Свидетельство о регистрации земельного участка 120 квартал Литер 2",
    subtitle: "",
    company: 1,
    file: "files/NSK/svid120kvL2.zip",
  },
  {
    id: 9,
    title: "Экспертиза 120 квартал Литер 2",
    subtitle: "",
    company: 1,
    file: "files/NSK/expert120kvL2.zip",
  },
  {
    id: 10,
    title: "Договор участия в долевом строительстве 120 квартал Литер 2",
    subtitle: "",
    company: 1,
    file: "files/NSK/dogovor120kvL2.zip",
  },
  {
    id: 11,
    title: "Аудиторское заключение за 2017г",
    subtitle: "",
    company: 1,
    file: "files/NSK/audit2017.zip",
  },
  {
    id: 12,
    title: "Бухгалтерский баланс за 2017г",
    subtitle: "",
    company: 1,
    file: "files/NSK/balans2017.zip",
  },
  {
    id: 13,
    title: "Бухгалтерский баланс за 1 квартал 2018г",
    subtitle: "",
    company: 1,
    file: "files/NSK/balans2018_kv1.zip",
  },
  {
    id: 14,
    title: "Разрешение на ввод 120 квартал",
    subtitle: "",
    company: 1,
    file: "files/NSK/razVvod260718.zip",
  },
  {
    id: 15,
    title: "Разрешение на строительство 120 квартал Литер 3",
    subtitle: "",
    company: 1,
    file: "files/NSK/razStrL3.zip",
  },
  {
    id: 16,
    title: "Свидетельство о регистрации земельного участка 120 квартал Литер 3",
    subtitle: "",
    company: 1,
    file: "files/NSK/svid120kvL3.zip",
  },
  {
    id: 17,
    title: "Заключение ЗОС 120 квартал Литер 3",
    subtitle: "",
    company: 1,
    file: "files/NSK/zakl120kvL3.zip",
  },
  {
    id: 18,
    title: "Бухгалтерский баланс за 2-ой квартал 2018г",
    subtitle: "",
    company: 1,
    file: "files/NSK/balans2018_kv2.zip",
  },
  {
    id: 19,
    title: "Шаблон договора участия в долевом строительстве",
    subtitle: "",
    company: 1,
    file: "files/NSK/dogovor120kvL3.zip",
  },
  {
    id: 20,
    title: "Экспертиза 120 квартал Литер 3",
    subtitle: "",
    company: 1,
    file: "files/NSK/expert_120kvL3.zip",
  },
  {
    id: 21,
    title: "Бухгалтерский баланс за 3-ой квартал 2018г",
    subtitle: "",
    company: 1,
    file: "files/NSK/balans2018_kv3.zip",
  },
  {
    id: 22,
    title: "Разрешение на ввод 425 квартал Литер 1",
    subtitle: "",
    company: 1,
    file: "files/NSK/razVvod425_1.zip",
  },
  {
    id: 23,
    title: "Разрешение на ввод 120 квартал Литер 3",
    subtitle: "",
    company: 1,
    file: "files/NSK/razVvod120_3.zip",
  },
  {
    id: 24,
    title: "Разрешение на ввод 425 квартал Литер 2",
    subtitle: "",
    company: 1,
    file: "files/NSK/razVvod_425(2).zip",
  },
  {
    id: 25,
    title: "Разрешение на строительство 425 квартал Литер 3",
    subtitle: "",
    company: 1,
    file: "files/NSK/razStr_425(3).zip",
  },
  {
    id: 26,
    title: "Разрешение на ввод 425 квартал Литер 3",
    subtitle: "",
    company: 1,
    file: "files/NSK/razVvod425_3.zip",
  },
  {
    id: 27,
    title: "Разрешение на строительство 425 квартал Литер 4",
    subtitle: "",
    company: 1,
    file: "files/NSK/razStr_425_3.zip",
  },
  {
    id: 28,
    title: "Выписка из реестра членов саморегулируемой организации",
    subtitle: "",
    company: 1,
    file: "files/NSK/sro.zip",
  },
  {
    id: 29,
    title: "Разрешение на ввод в эксплуатацию 425 квартал Литер 4",
    subtitle: "Опубликовано 07 ноября 2022",
    company: 1,
    file: "files/NSK/Vvod_425.zip",
  },
  {
    id: 30,
    title: "Аудиторское заключение",
    subtitle: "Опубликовано 18 августа 2023",
    company: 1,
    file: "files/NSK/audit_nsk.zip",
  },





  

  {
    id: 1001,
    title: "Разрешение на ввод 150 квартал Литер 4",
    subtitle: "",
    company: 2,
    file: "files/ASZ/razVvod150kvL4.zip.zip",
  },
  {
    id: 1002,
    title: "Разрешение на ввод 34 квартал",
    subtitle: "",
    company: 2,
    file: "files/ASZ/razVvod34kv.zip",
  },
  {
    id: 1003,
    title: "Разрешение на строительство 168 квартал",
    subtitle: "",
    company: 2,
    file: "files/ASZ/razStr_168.zip",
  },
  {
    id: 1004,
    title: "Разрешение на ввод 168 квартал",
    subtitle: "",
    company: 2,
    file: "files/ASZ/razVvod168kv.zip",
  },
  {
    id: 1005,
    title: "Выписка из реестра членов саморегулируемой организации",
    subtitle: "",
    company: 2,
    file: "files/ASZ/sro.zip",
  },

  {
    id: 2001,
    title: "Разрешение на строительство 121 квартал",
    subtitle: "",
    company: 3,
    file: "files/MONTAZ/razStr_121_21-07-24.zip",
  },
  {
    id: 2002,
    title: "Разрешение на ввод 121 квартал",
    subtitle: "",
    company: 3,
    file: "files/MONTAZ/mont_121_07-11-23.zip",
  },
  {
    id: 2003,
    title: "Разрешение на ввод 5 квартал",
    subtitle: "",
    company: 3,
    file: "files/MONTAZ/mont_5_21-07-24.zip",
  },

]

export default documentationBD;