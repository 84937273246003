import React, { useContext, useEffect, useState } from "react";
import { AuthContext } from "../context";
import DefaultNavbar from "../examples/Navbars/DefaultNavbar";
import routes from "../routes";
import Card from "@mui/material/Card";
import Container from "@mui/material/Container";
import Grid from "@mui/material/Grid";
import MKBox from "../components/MKBox";
import DefaultFooter from "../examples/Footers/DefaultFooter";
import footerRoutes from "../footer.routes";
import MKInput from "../components/MKInput";
import MKButton from "../components/MKButton";


const Login = () => {
  const { isAuth, setIsAuth } = useContext(AuthContext);
  const [message, setMessage] = useState("");
  const login = (event) => {
    event.preventDefault();
    const login = event.currentTarget.elements.login.value;
    const password = event.currentTarget.elements.password.value;
    if (login === "admin" && password === "1qaz@WSX") {
      setIsAuth(true);
      setMessage("Добро пожаловать, Админ!");
    } else {
      setMessage("Неверный логин/пароль");
    }

    console.log(isAuth);
  };
  useEffect(() => {
    if (isAuth) {
      setMessage("Вы вошли как Админ");
    }
  }, [isAuth]);

  console.log(isAuth);
  return (

    <>
      <DefaultNavbar
        routes={routes}
      />
      <div style={{ height: "100px" }}/>
      <Card
        sx={{
          p: 2,
          mx: { xs: 2, lg: 3 },
          mt: -8,
          mb: 4,
          boxShadow: ({ boxShadows: { xxl } }) => xxl,
        }}
      >
        <div style={{ height: "50px" }}/>

        <Container>
          <Grid container alignItems="flex-start">
            <div style={{ display: "block" }}>
              {isAuth
                ? null
                :
                <form onSubmit={login}>
                  <Grid container spacing={3}>

                    <Grid item xs={12} md={6}>

                      <MKInput
                        variant="standard"
                        id="login"
                        type="text"
                        label="Введите логин"
                        InputLabelProps={{ shrink: true }}
                        fullWidth
                      />
                    </Grid>
                    <Grid item xs={12} md={6}>

                      <MKInput
                        variant="standard"
                        id="password"
                        type="password"
                        label="Введите пароль"
                        InputLabelProps={{ shrink: true }}
                        fullWidth
                      />
                    </Grid>
                  </Grid>
                  <Grid container item justifyContent="left" xs={12} mt={5} mb={2}>
                    <MKButton type="submit" variant="gradient" color="info">
                      Войти
                    </MKButton>
                  </Grid>

                </form>
              }
              <div style={{ minHeight: "50px" }}>{message}</div>

            </div>

          </Grid>
        </Container>

      </Card>
      <Card
        sx={{
          p: 2,
          mx: { xs: 2, lg: 3 },
          mt: -8,
          mb: 4,
          boxShadow: ({ boxShadows: { xxl } }) => xxl,
        }}
      >

        <Container>
          <MKBox component="section" pt={6} my={6}>
            <Grid container spacing={3} alignItems="center">
            </Grid>
          </MKBox>
        </Container>
      </Card>

      <MKBox pt={6} px={1} mt={6}>
        <DefaultFooter content={footerRoutes}/>
      </MKBox>
    </>

  );
};

export default Login;