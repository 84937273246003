import React from "react";
import MKBox from "../components/MKBox";
import DefaultFooter from "../examples/Footers/DefaultFooter";
import footerRoutes from "../footer.routes";
import DefaultNavbar from "../examples/Navbars/DefaultNavbar";
import routes from "../routes";
import Container from "@mui/material/Container";
import Grid from "@mui/material/Grid";
import Card from "@mui/material/Card";
import { useParams } from "react-router-dom";
import newsBD from "../data/newsBD";


const NewstIdPage = () => {
  const {id} = useParams();
  const news = newsBD.find(el=>el.id==Number(id));
  return (
    <div>

      <DefaultNavbar
        routes={routes}
      />

      <div style={{ height: "100px" }}/>
      <Card
        sx={{
          p: 2,
          mx: { xs: 2, lg: 3 },
          mt: -8,
          mb: 4,
          boxShadow: ({ boxShadows: { xxl } }) => xxl,
        }}
      >

        <Container>
          <MKBox component="section" pt={6} my={6}>

            <Grid container spacing={3} alignItems="center">

              <div className="news news_detailed">
                {news.image
                  ? <img src={require(`assets/${news.image}`).default} title="" alt={news.title}/>
                  : null
                }
                <p>
                      <span className="news__text">
                        <span className="news__date">{news.date}</span>
                        {news.desc}
                      </span>
                </p>
              </div>

            </Grid>
          </MKBox>

        </Container>
      </Card>


      <MKBox pt={6} px={1} mt={6}>
        <DefaultFooter content={footerRoutes}/>
      </MKBox>
    </div>
  );
};

export default NewstIdPage;