import BasicTabs from "./BasicTabs";
import React from "react";
import { LightgalleryItem } from "react-lightgallery";
import objectsBD from "../data/objectsBD";

const TabsObject = ({ id }) => {
  const paramTabs = objectsBD.find(el => el.id == Number(id)).tabs;

  return (paramTabs !== undefined ?
        <BasicTabs params={paramTabs} border={true}/>
      : null
  );
};
export default TabsObject;






