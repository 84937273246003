import React from "react";
import ModalWindow from "../components/ModalWindow";

const newsBD = [
  {
    id: 104,
    date: "10.05.2023",
    image: "",
    title: "Получено разрешение на строительство Здания общественного назначения в 5 квартале г. Благовещенска № 28-01-19-2023. Строительство дома ведется компанией ООО \"Комплектстройсервис\" в районе улиц Горького- Загородная",
    desc: "ModalWindow",
    detailed: false,
  },
  {
    id: 103,
    date: "11.04.2023",
    image: "",
    title: "Получено разрешение на строительство Многоквартирных домов Лите 1 и Литер 2 в 5 квартале г. Благовещенска № 28-01-26-2023. Строительство дома ведется компанией ООО \"СЗ \"Монтажжилстрой\" в районе улиц Горького- Загородная",
    desc: "ModalWindow",
    detailed: false,
  },
  {
    id: 102,
    date: "21.08.2022",
    image: "",
    title: "Получено разрешение на строительство МЖД в 121 квартале г. Благовещенска № 28-Кг28302000-63-2022. Строительство дома ведется компанией ООО \"СЗ \"Монтажжилстрой\" в районе улиц Артиллерийская - Красноармейская",
    desc: "ModalWindow",
    detailed: false,
  },
  {
    id: 101,
    date: "02.11.2022",
    image: "",
    title: "Сдан в эксплуатацию многоквартирный жилой дом в 425 квартале г. Благовещенска  ЖК \"Светлый\"",
    desc: "ModalWindow",
    detailed: false,
  },
  {
    id: 100,
    date: "28.09.2020",
    image: "",
    title: "МЖД в 425 квартале Литер 3 г.Благовещенска получено разрешение на ввод в эксплуатацию.",
    desc: "МЖД в 425 квартале Литер 3 г.Благовещенска получено разрешение на ввод в эксплуатацию.",
    detailed: false,
  },
  {
    id: 99,
    date: "11.09.2020",
    image: "",
    title: <><span>Уведомление о проведении внеочередного собрания общества с ограниченной ответственностью "Специализированный застройщик "Амурстройзаказчик"</span>
      <div><a href={require(`assets/files/ASZ/УведомлениеАСЗ(3).zip`).default}>Скачать документ</a></div>
    </>,
    desc: "",
    detailed: false,
  },
  {
    id: 98,
    date: "11.09.2020",
    image: "",
    title: <><span>Решение о продлении сроков внеочередного собрания общества с ограниченной ответственностью "Специализированный застройщик "Амурстройзаказчик"</span>
      <div><a href={require(`assets/files/ASZ/РешениеАСЗ.zip`).default}>Скачать документ</a></div>
    </>,
    desc: "",
    detailed: false,
  },
  {
    id: 97,
    date: "31.08.2020",
    image: "",
    title: "Уведомление о проведении заочного внеочередного собрания участников общества с ограниченной ответственностью \"Специализированный застройщик \"Народная строительная компания\" Скачать документ",
    desc: "Уведомление о проведении заочного внеочередного собрания участников общества с ограниченной ответственностью \"Специализированный застройщик \"Народная строительная компания\" Скачать документ",
    detailed: false,
  },
  {
    id: 96,
    date: "31.08.2020",
    image: "",
    title: "Уведомление о проведении заочного внеочередного собрания участников общества с ограниченной ответственностью \"Специализированный застройщик \"Амурстройзаказчик\" Скачать документ",
    desc: "",
    detailed: false,
  },
  {
    id: 95,
    date: "09.06.2020",
    image: "",
    title: "МЖД в 425 квартале Литер 2 г.Благовещенска получено разрешение на ввод в эксплуатацию.",
    desc: "",
    detailed: false,
  },
  {
    id: 94,
    date: "29.08.2019",
    image: "",
    title: "МЖД в 120 квартале Литер 3 г. Благовещенска получено разрешение на ввод в эксплуатацию.",
    desc: "",
    detailed: false,
  },
  {
    id: 93,
    date: "03.07.2019",
    image: "",
    title: "МЖД в 425 квартале Литер 1 г. Благовещенска получено разрешение на ввод в эксплуатацию.",
    desc: "МЖД в 425 квартале Литер 1 г. Благовещенска получено разрешение на ввод в эксплуатацию.",
    detailed: false,
  },


  {
    id: 92,
    date: "23.10.2018",
    image: "",
    title: "МЖД в 120 квартале Литер 3 г. Благовещенска аккредитован АО Россельхозбанк",
    desc: "",
    detailed: false,
  },
  {
    id: 91,
    date: "15.10.2018 ",
    image: "",
    title: "МЖД в 120 квартале Литер 3 г. Благовещенска аккредитован ПАО ВТБ.",
    desc: "",
    detailed: false,
  },
  {
    id: 90,
    date: "09.06.2020",
    image: "",
    title: "МЖД в 425 квартале Литер 2 г.Благовещенска получено разрешение на ввод в эксплуатацию.",
    desc: "",
    detailed: false,
  },
  {
    id: 89,
    date: "09.10.2018",
    image: "",
    title: "МЖД в 120 квартале Литер 3 г. Благовещенска аккредитован в Совкомбанке",
    desc: "",
    detailed: false,
  },
  {
    id: 88,
    date: "04.10.2018 ",
    image: "",
    title: "МЖД в 120 квартале Литер 3 г. Благовещенска аккредитован ПАО Сбербанк России",
    desc: "",
    detailed: false,
  },
  {
    id: 87,
    date: "26.09.2018",
    image: "",
    title: "Внимание! Открыта продажа квартир в ЖК \"Новый\" ( многоквартирный жилой дом в 120 квартале г. Благовещенска , 3 очередь).",
    desc: "",
    detailed: false,
  },
  {
    id: 86,
    date: "23.09.2017",
    image: "",
    title: "Началась продажа 2-ой очереди многоквартирного жилого дома в 120-ом квартале г.Благовещенска. В наличии есть трехкомнатные, двухкомнатные и однокомнатные квартиры.",
    desc: "",
    detailed: false,
  },
  {
    id: 85,
    date: "07.08.2017",
    image: "",
    title: "Началась передача квартир участникам долевого строительства.",
    desc: "",
    detailed: false,
  },
  {
    id: 84,
    date: "06.06.2017",
    image: "",
    title: "Многоквартирный жилой дом в 120 квартале г. Благовещенска Литер 1 сдан в эксплуатацию.",
    desc: "",
    detailed: false,
  },
  {
    id: 83,
    date: "08.04.2017",
    image: "",
    title: <>08 апреля 2017 года состоялась 6-я Ярмарка новостроек. ООО "Амурстройзаказчик" были разыграны три
      сертификата на сумму 100 000 рублей каждый.
      <div>Счастливыми обладателями сертификатов стали:</div>
      <div style={{ marginLeft: "20px" }}>Шальнева Татьяна Владимировна</div>
      <div style={{ marginLeft: "20px" }}>Хуснутдинова Оксана Михайловна</div>
      <div style={{ marginLeft: "20px" }}>Поддубная Валентина Павловна</div>
      <p style={{ marginTop: "10px" }}>Желаем счастливым обладателям отличного выбора квартиры в наших домах,
        комфортного проживания в ней, здоровья, счастья и благополучия.</p>
    </>,
    desc: "",
    detailed: false,
  },
  {
    id: 82,
    date: "14.02.2017",
    image: "images/news/Akcia1.png",
    title: <>Внимание акция!
      <div><ModalWindow img={"images/news/Akcia3(1).jpg"} width={"220px"} height={"200px"} center={false}></ModalWindow></div>
    </>,
    desc: "",
    detailed: false,
  },
  {
    id: 81,
    date: "06.12.2016",
    image: "images/news/Akcia1.png",
    title:
      <>
        <div>Внимание акция! Снижены цены на следующие квартиры:</div>
        <div>В 150 квартале г.Благовещенска 1комн., 1-ый этаж, 48.7м2 - 2600 тыс.руб; 2комн., 4-ый этаж, 57.6м2 - 3100
          тыс.руб.
        </div>
        <div>В 120 квартале г.Благовещенска две гостинки на 2-ом этаже, 27.4м2 - 1550 тыс.руб.</div>
      </>,
    desc: "",
    detailed: false,
  },
  {
    id: 80,
    date: "24.08.2016",
    image: "images/news/Akcia1.png",
    title:
      <>
        <div>Объявлена акция по снижению цены на гостинки в МЖД в 34 квартале г. Благовещенска по ул. Б-Хмельницкого,
          13.
        </div>
        <div>Ранее цена квартиры 21,3 кв.м. составляла 1405000 руб. Сейчас по акции квартира будет стоить 1350000.</div>
        <div>Спешите. Количество квартир ограничено</div>
      </>,

    desc: "",
    detailed: false,
  },
  {
    id: 79,
    date: "24.08.2016",
    image: "images/news/Akcia1.png",
    title:
      <>
        <div>Объявлена акция по снижению цены на двухкомнатные квартиры в МЖД в 150 квартале г. Благовещенска по ул.
          Комсомольская, 89.
        </div>
        <div>Ранее цена квартиры 57,5 кв.м составляла 3280000 рублей. Сейчас по акции квартира будет стоить 2999000.
        </div>
        <div>Спешите. Количество квартир ограничено</div>
      </>,

    desc: "",
    detailed: false,
  },
  {
    id: 78,
    date: "02.08.2016",
    image: "",
    title: "Сдан многоквартирный жилой дом в 34 квартале г. Благовещенска",
    desc: "",
    detailed: false,
  },

  {
    id: 77,
    date: "01.08.2016",
    image: "",
    title: <><span>ТОП застройщиков жилья Амурской области</span>
      <div><a href={require(`assets/files/top.zip`).default}>Скачать файл</a></div>
    </>,
    desc: "",
    detailed: false,
  },

  {
    id: 76,
    date: "01.08.2016",
    image: "images/news/goldMedal.png",
    title: "На сайте Федерального некоммерческого проекта \"Надежные новостройки России\" опубликован список надежных застройщиков Амурской области.",
    desc:
      <div>
        <span>На сайте Федерального некоммерческого проекта "Надежные новостройки России" опубликован список надежных
          застройщиков Амурской области.
        </span>
        <div style={{ marginTop: "10px" }}></div>
        <p>По версии проекта надежной является компания, все строящиеся объекты которой имеют максимальные показатели
          надежности соблюдения законных прав и интересов участников долевого строительства, не имеют претензий со
          стороны контролирующих органов и жалоб со стороны покупателей.</p>
        <p>В Амурской области надежными признаны три компании:</p>
        <div style={{ marginTop: "10px" }}></div>
        <div>1. Амурстройзаказчик</div>
        <div>2. АНК</div>
        <div>3. Благовещенскстрой.</div>
        <div style={{ marginTop: "10px" }}></div>
        <div> По согласованию с органами исполнительной власти, осуществляющих надзор в области долевого строительства,
          компании номинированы на награждение Золотым знаком "Надежный застройщик России".
        </div>
        <div> Всего по Дальневосточному Федеральному Округу в номинацию попали 19 компаний. Церемония награждения
          пройдет на Всероссийском саммите "Надежный застройщик России 2016" 3 ноября в Правительственной резиденции
          Государственном комплексе "Дворец конгрессов" (Санкт-Петербург, Стрельна).
        </div>
        <div>Главной общественной наградой будут отмечены 200 строительных компаний со всех регионов РФ.</div>
        <div> Федеральный некоммерческий проект "Надежные новостройки России" реализуется Фондом Развития механизмов
          гражданского контроля при поддержке региональных контролирующих органов 21 субъекта Российской Федерации.
        </div>
        <div style={{ marginTop: "10px" }}></div>
        <div>Ссылки:</div>
        <div><a href="http://dozor214.ru/sammit3.html">http://dozor214.ru/sammit3.html</a></div>
        <div><a href="http://dozor214.ru/gold6.html">http://dozor214.ru/gold6.html</a></div>
      </div>
    ,
    detailed: true,
  },


  {
    id: 75,
    date: "27.04.2016",
    image: "",
    title: "Внимание! Компания Амурстройзаказчик объявляет акцию ко Дню города! 5 квадратных метров в подарок в гостинке в многоквартирном доме по адресу Богдана Хмельницкого 13.",
    desc: "",
    detailed: false,
  },
  {
    id: 74,
    date: "27.04.2016",
    image: "images/news/hot_offer.png",
    title: "В преддверии первомайских праздников продается одна квартира гостиничного типа в доме по ул. Б-Хмельницкого, 13 на 10 этаже по цене 62 тыс 1 кв.м. Площадь 21,3 кв.м. Цена 1320 тыс руб. Желательно оплата наличными.",
    desc: "",
    detailed: false,
  },

  {
    id: 73,
    date: "15.04.2016",
    image: "",
    title: <>Внимание акция!
      <div><ModalWindow img={"images/news/Akcia2.jpg"} width={"300px"} height={"200px"} center={false}></ModalWindow></div>
    </>,
    desc: "",
    detailed: false,
  },

  {
    id: 72,
    date: "12.04.2016",
    image: "images/news/Akcia1.png",
    title:
      <>
        <div>Внимание! Акция! Гараж в подарок при покупке 1-комн. квартиры (48 кв.м.) или 2-комн. квартиры (57 кв.м.) в
          доме по ул. Комсомольская, 89. Дом сдан. Гаражи находятся в районе магазина Светофор. Телефон отдела продаж
          8-914-384-78-68.
        </div>
      </>,

    desc: "",
    detailed: false,
  },


  {
    id: 71,
    date: "11.02.2016",
    image: "",
    title: "Открыта продажа квартир в многоквартирном 9-этажном жилом доме в 120 квартале г. Благовещенска (район ул. Красноармейская - Новая, за стадионом школы № 6). Сдача дома 3 квартал 2017 года.",
    desc: "",
    detailed: false,
  },
  {
    id: 70,
    date: "08.02.2016",
    image: "",
    title: "Акция. 1-комнатные квартиры по 54 тыс.рублей за кв.м. С 08.02.2016 по 15.03.2016 года объявлена акция: снижение цены 1 кв.м. на однокомнатные квартиры в МЖД по ул.Комсомольская, 89 площадью 48 кв.м. на первом и четырнадцатом этаже.",
    desc: "",
    detailed: false,
  },
  {
    id: 69,
    date: "16.12.2015",
    image: "",
    title: "Внимание! Снижение цен на квартиры в МЖД по ул. Б-Хмельницкого, 13 ( 34 квартал г. Благовещенска). Цена 2-х комн. квартир - 61 тысрублей / кв.м. 1-комн квартир - 64 тыс.рублей / кв.м. гостинки - 66 тыс рублей/кв.м. Страховку платит строительная компания.",
    desc: "",
    detailed: false,
  },
  {
    id: 68,
    date: "16.11.2015",
    image: "",
    title: "Подписан договор с ООО \"Региональная страховая компания\" по страхованию гражданской ответственности застройщика за неисполнение или ненадлежащее исполнение обязательств по передаче жилого помещения по договору участия в долевом строительстве по объекту МЖД в 34 квартале г. Благовещенска по ул. Б-Хмельницкого, 13.",
    desc: "",
    detailed: false,
  },
  {
    id: 67,
    date: "27.08.2015",
    image: "",
    title: "Получено разрешение на ввод в эксплуатацию объекта в 150 квартале г.Благовещенска (4 очередь) - 6 подъезд многоквартирного жилого дома по ул. Комсомольской, 89",
    desc: "",
    detailed: false,
  },
  {
    id: 66,
    date: "29.06.2015",
    image: "",
    title: "Объявленение. В доме по ул. Комсомольска, 89 в 6 подъезде (сдача август 2015 года) срочно продается одна 2-комн. квартира площадью 57,5 кв.м. по цене 55 тыс рублей за кв.м.",
    desc: "",
    detailed: false,
  },

  {
    id: 66,
    date: "26.06.2015",
    image: "",
    title: "Внимание! Начата ограниченная продажа квартир в МЖД по ул. Б-Хмельницкого, 13 ( 34 квартал г. Благовещенска). Цена 2-х комн. квартир - 65 тысрублей / кв.м. 1-комн квартир - 67 тыс.рублей / кв.м. гостинки - 70 тыс рублей/кв.м.",
    desc: "",
    detailed: false,
  },

  {
    id: 65,
    date: "01.05.2015",
    image: "images/news/AkciaFinished.png",
    title:
      <>
        <div>Майская акция! Все подробности внутри.
        </div>
      </>,
    desc:
      <>
        <img style={{width: "100%", height: "100%" }} src={require(`assets/images/news/Akcia4.jpg`).default}/>
      </>,
    detailed: true,
  },

  {
    id: 64,
    date: "20.03.2015",
    image: "images/news/AkciaFinished.png",
    title:
      <>
        <div>С 20 марта 2015 года по 30 апреля 2015 года объявлена весенняя акция. Цена 1 кв. метра на однокомнатные квартиры в сданном доме по ул. Комсомольская, 89 - 56 000 рублей.
        </div>
      </>,
    desc:<></>,
    detailed: false,
  },
  {
    id: 63,
    date: "15.12.2014",
    image: "images/news/AkciaFinished.png",
    title:
      <>
        <div>ООО "Амурстройзаказчик" с 15 декабря 2014 года объявляет Новогоднюю акцию "Зимние скидки" на северные 1-комнатные квартиры в доме по ул. Комсомольская, 89. "Зимняя цена" одного квадратного метра - 54 тысячи рублей.
        </div>
      </>,
    desc:<></>,
    detailed: false,
  },
  {
    id: 62,
    date: "22.09.2014",
    image: "images/news/AkciaFinished.png",
    title:
      <>
        <div>c 22.09.2014 по 31.10.2014 года ООО «Амурстройзаказчик» объявляет акцию – «Нереальные цены на квартиры в центре города»
        </div>
      </>,
    desc:<></>,
    detailed: false,
  },

  {
    id: 61,
    date: "31.07.2014",
    image: "images/news/komsomolskaya_89.jpg",
    title:
      <>
        <div>Сегодня сдана 3 очередь (4-5 подъезды) многоквартирного жилого дома по ул. Комсомольской, 89
        </div>
      </>,
    desc:<></>,
    detailed: false,
  },

  {
    id: 60,
    date: "30.06.2014",
    image: "images/news/AkciaFinished.png",
    title:
      <>
        <div>ООО «Народная строительная компания» объявляет акцию до 31.08.2014 года. Трем первым покупателям на однокомнатные квартиры площадью 39,5 и 40,5 кв.м. в доме по ул. Зейская, 53 эконом ремонт в подарок.
        </div>
      </>,
    desc:<></>,
    detailed: false,
  },

  {
    id: 59,
    date: "26.05.2014",
    image: "images/news/AkciaFinished.png",
    title:
      <>
        <div>ООО «Народная строительная компания» объявляет акцию до 31.07.2014 года. Трем первым покупателям на двухкомнатные квартиры площадью 52 и 62 кв.м. в доме по ул. Зейская, 53 эконом ремонт в подарок.
        </div>
      </>,
    desc:<></>,
    detailed: false,
  },

  {
    id: 58,
    date: "Апрель 2014",
    image: "",
    title:
      <>
        <div>ООО «Амурстройзаказчик» (генеральный директор Лагутин Анатолий Семенович) становится лауреатом конкурса «Строитель года» в номинации «Меценат года – за благотворительную деятельность в строительной отрасли».
        </div>
      </>,
    desc:<></>,
    detailed: false,
  },
  {
    id: 57,
    date: "01.11.2013",
    image: "images/news/AkciaFinished.png",
    title:
      <>
        <div>ООО «Народная строительная компания» объявляет акцию о снижении стоимости квадратного метра жилья на 2-х комнатные квартиры площадью 52 кв.м. и 62 кв.м. на 8 и 9 этаже в доме по адресу ул. Зейская, 53. Цена квадратного метра снижена до 56 тыс.рублей за кв.метр.
        </div>
      </>,
    desc:<></>,
    detailed: false,
  },

  {
    id: 56,
    date: "08.08.2013",
    image: "images/news/Zeyskaya_53_p3_big.jpg",
    title:
      <>
        <div>Cдан в эксплуатацию многоквартирный жилой дом со встроенными помещениями общественного назначения Литер 4, 3 этап в 87 квартале г.Благовещенска по адресу: ул.Зейская 53.
        </div>
      </>,
    desc:<></>,
    detailed: false,
  },
  {
    id: 55,
    date: "17.04.2013",
    image: "images/news/Stroitel_2012.jpg",
    title:
      <>
        <div>Подведены итоги конкурса "Строитель года Амурской области 2012". ООО "Амурстройзаказчик" является лауреатом в номинации "Лучшая компания в жилищном строительстве" в номинации "Меценат года".
        </div>
      </>,
    desc:<></>,
    detailed: false,
  },
  {
    id: 54,
    date: "01.11.2012",
    image: "images/news/Zeyskaya_53_p3_big.jpg",
    title:
      <>
        <div>Открыта продажа квартир в строящемся многоквартирном жилом доме в 87 квартале г.Благовещенска, Литер 4 3 этап по адресу: ул.Зейская 53.
        </div>
      </>,
    desc:<></>,
    detailed: false,
  },
  {
    id: 53,
    date: "08.08.2012",
    image: "",
    title:
      <>
        <div>Cдан в эксплуатацию многоквартирный жилой дом со встроенными помещениями общественного назначения, 2 очередь в 150 квартале г.Благовещенска по адресу: ул.Пролетарская 89.
        </div>
      </>,
    desc:<></>,
    detailed: false,
  },
  {
    id: 52,
    date: "25.07.2012",
    image: "images/news/1.jpg",
    title: "Cдан в эксплуатацию многоквартирный жилой дом со встроенными помещениями общественного назначения Литер 4, 2 этап в 87 квартале г.Благовещенска по адресу: ул.Зейская 53.",
    desc: "",
    detailed: false,
  },

];


export default newsBD;