import React from "react";
import { LightgalleryItem } from "react-lightgallery";

const ModalWindow = (props) => {
  const maxHeight = (props.height !== undefined) ? (props.height) : "500px"
  const maxWidth = (props.width !== undefined) ? (props.width) : "500px"
  const center = (props.center !== undefined) ? (props.center) : true
  const realStyle = {
    display: "block",
    margin: (center) ? ("0 auto") : "",
    cursor: "pointer",
    width: "100%",
    height: "100%",
    maxHeight: maxHeight,
    maxWidth: maxWidth,

  }
  return (
      <LightgalleryItem src={require(`assets/${props.img}`).default}>
        <img style={realStyle} src={require(`assets/${props.img}`).default}/>
      </LightgalleryItem>
  );
};

export default ModalWindow;