import React from "react";
import newsBD from "../data/newsBD";
import Grid from "@mui/material/Grid";
import MKBox from "./MKBox";
import { Link } from "react-router-dom";
import docDeclarationBD from "../data/docDeclarationBD";
import iconDoc from "../assets/images/doc.png";
import MKTypography from "./MKTypography";

const FetchDocs = (props) => {
  const docs = props.bd.filter(el => el.company == props.company);
  return (
    <div>
      {docs.map((doc) => (
        <Grid item xs={12} md={12} sx={{ mb: 3 }} key={doc.id}>
          <div className="row">
            <div className="row__content"  style={{ display: "flex", flexDirection: "row" }}>
              <div >
                <div style={{padding: "0 10px 0 10px" }}>
                  <img src={iconDoc}/>
                </div>

              </div>
              <a className="row__link" href={require(`assets/${doc.file}`).default} target="_blank">
                <MKTypography variant="h6" style={{color:"rgb(26,117,255)"}}>
                  {doc.title}
                </MKTypography>
                <MKTypography variant="h6" fontWeight={"light"} style={{color:"rgb(204, 204, 204)"}}>
                  {doc.subtitle}
                </MKTypography>
              </a>

            </div>
          </div>

        </Grid>
      ))}
    </div>
  );
};

export default FetchDocs;