const docDeclarationBD = [
  {
    id: 1,
    title: "1.ПРОЕКТНАЯ ДЕКЛАРАЦИЯ 87 квартал Литер 4 (1 очередь) от 28-05-10",
    subtitle: "Опубликовано 12 мая 2010",
    company: 1,
    file: "files/NSK/D280510.zip",
  },
  {
    id: 2,
    title: "2.Изменения к проектной декларации 87 квартал Литер 4 (1 очередь) от 12-10-10",
    subtitle: "Опубликовано 12 октября 2010",
    company: 1,
    file: "files/NSK/13-10-10.zip",
  },
  {
    id: 3,
    title: "3.Изменения к проектной декларации 87 квартал Литер 4 (1 очередь) от 28-02-11",
    subtitle: "Опубликовано 28 февраля 2011",
    company: 1,
    file: "files/NSK/04-03-11.zip",
  },
  {
    id: 4,
    title: "4.Изменения к проектной декларации 87 квартал Литер 4 (1 очередь) от 12-04-11",
    subtitle: "Опубликовано 12 апреля 2011",
    company: 1,
    file: "files/NSK/13-04-11.zip",
  },
  {
    id: 5,
    title: "5.ПРОЕКТНАЯ ДЕКЛАРАЦИЯ 87 квартал Литер 4 (2 очередь) от 23-05-11",
    subtitle: "Опубликовано 23 мая 2011",
    company: 1,
    file: "files/NSK/23-05-11PD.zip",
  },
  {
    id: 6,
    title: "6.Изменения к проектной декларации 87 квартал Литер 4 (2 очередь) от 12-07-11",
    subtitle: "Опубликовано 12 июля 2011",
    company: 1,
    file: "files/NSK/12-07-11_87-2.zip",
  },
  {
    id: 7,
    title: "7.Изменения к проектной декларации 87 квартал Литер 4 (1 очередь) от 12-07-11",
    subtitle: "Опубликовано 12 июля 2011",
    company: 1,
    file: "files/NSK/12-07-11_87-1.zip",
  },
  {
    id: 8,
    title: "8.Изменения к проектной декларации 87 квартал Литер 4 (1 очередь) от 14-10-11",
    subtitle: "Опубликовано 10 октября 2011",
    company: 1,
    file: "files/NSK/14-10-1187-1.zip",
  },
  {
    id: 9,
    title: "9.Изменения к проектной декларации 87 квартал Литер 4 (2 очередь) от 14-10-11",
    subtitle: "Опубликовано 14 октября 2011",
    company: 1,
    file: "files/NSK/14-10-1187-2.zip",
  },
  {
    id: 10,
    title: "10.Изменения к проектной декларации 87 квартал Литер 4 (2 очередь) от 05-12-11",
    subtitle: "Опубликовано 5 декабря 2011",
    company: 1,
    file: "files/NSK/05-12-11.zip",
  },
  {
    id: 11,
    title: "11.Изменения к проектной декларации 87 квартал Литер 4 (2 очередь) от 30-01-12",
    subtitle: "Опубликовано 30 января 2012",
    company: 1,
    file: "files/NSK/31-01-12.zip",
  },
  {
    id: 12,
    title: "12.Изменения к проектной декларации 87 квартал Литер 4 (2 очередь) от 10-01-12",
    subtitle: "Опубликовано 10 января 2012",
    company: 1,
    file: "files/NSK/17-01-12.zip",
  },
  {
    id: 13,
    title: "13.Изменения к проектной декларации 87 квартал Литер 4 (2 очередь) от 25-04-12",
    subtitle: "Опубликовано 25 апреля 2012",
    company: 1,
    file: "files/NSK/25-04-12.zip",
  },
  {
    id: 14,
    title: "14.ПРОЕКТНАЯ ДЕКЛАРАЦИЯ 87 квартал Литер 4 (3 очередь) 05-05-12",
    subtitle: "Опубликовано 5 мая 2012",
    company: 1,
    file: "files/NSK/15-05-12.zip",
  },
  {
    id: 15,
    title: "15.Изменения к проектной декларации 87 квартал Литер 4 (3 очередь) от 19-07-12  ",
    subtitle: "Опубликовано 19 июля 2012",
    company: 1,
    file: "files/NSK/NSK_87_31.zip",
  },
  {
    id: 16,
    title: "16.Изменения к проектной декларации 87 квартал Литер 4 (2 очередь) от 23-07-12",
    subtitle: "Опубликовано 23 июля 2012",
    company: 1,
    file: "files/NSK/nsk23.07.2012.zip",
  },
  {
    id: 17,
    title: "17.Изменения к проектной декларации 87 квартал Литер 4 (2 очередь) от 23-10-12",
    subtitle: "Опубликовано 23 октября 2012",
    company: 1,
    file: "files/NSK/nsk231012.zip",
  },
  {
    id: 18,
    title: "18.Изменения к проектной декларации 87 квартал Литер 4 (3 очередь) от 23-10-12",
    subtitle: "Опубликовано 23 октября 2012",
    company: 1,
    file: "files/NSK/nsk231012_2.zip",
  },
  {
    id: 19,
    title: "19.Изменения к проектной декларации 87 квартал Литер 4 (3 очередь) от 20-12-12",
    subtitle: "Опубликовано 20 декабря 2012",
    company: 1,
    file: "files/NSK/NSK_D_87_2.zip",
  },
  {
    id: 20 ,
    title: "20.Изменения к проектной декларации 87 квартал Литер 4 (3 очередь) от 26-03-13",
    subtitle: "Опубликовано 26 марта 2013",
    company: 1,
    file: "files/NSK/nsk_26-03-13.zip",
  },
  {
    id: 21,
    title: "21.Изменения к проектной декларации 87 квартал Литер 4 (3 очередь) от 29-04-13",
    subtitle: "Опубликовано 29 апреля 2013",
    company: 1,
    file: "files/NSK/87_3_29-04-13.zip",
  },
  {
    id: 22,
    title: "22.Изменения к проектной декларации 87 квартал Литер 4 (3 очередь) от 10-06-13",
    subtitle: "Опубликовано 10 июня 2013",
    company: 1,
    file: "files/NSK/87_10-06-13.zip",
  },
  {
    id: 23,
    title: "23.Изменения к проектной декларации 87 квартал Литер 4 (3 очередь) от 24-07-13",
    subtitle: "Опубликовано 24 июля 2013",
    company: 1,
    file: "files/NSK/NSK_24-07-13.zip",
  },
  {
    id: 24,
    title: "24.Изменения к проектной декларации 87 квартал Литер 4 (3 очередь) от 06-08-13",
    subtitle: "Опубликовано 6 августа 2013",
    company: 1,
    file: "files/NSK/87_06-08-13.zip",
  },
  {
    id: 25,
    title: "25.Изменения к проектной декларации в 87 квартал Литер 4 (3 очередь) от 24-10-13",
    subtitle: "Опубликовано 24 октября 2013",
    company: 1,
    file: "files/NSK/NSK_24-10-13.zip",
  },
  {
    id: 26,
    title: "26.ПРОЕКТНАЯ ДЕКЛАРАЦИЯ в 120 квартале от 19-02-15  ",
    subtitle: "Опубликовано 19 февраля 2015",
    company: 1,
    file: "files/NSK/NSK_D_19-02-15.zip",
  },
  {
    id: 27,
    title: "27.Изменения к проектной декларации в 120 квартале от 24-03-15",
    subtitle: "Опубликовано 24 марта 2015",
    company: 1,
    file: "files/NSK/nsk_120_24-03-15.zip",
  },
  {
    id: 28,
    title: "28.Изменения к проектной декларации в 120 квартале от 28-04-15",
    subtitle: "Опубликовано 28 апреля 2015",
    company: 1,
    file: "files/NSK/nsk_120_28-04-15.zip",
  },
  {
    id: 29,
    title: "29.Изменения к проектной декларации в 120 квартале от 10-06-15",
    subtitle: "Опубликовано 10 июня 2015",
    company: 1,
    file: "files/NSK/nsk_120_10-06-15.zip",
  },
  {
    id: 30,
    title: "30.Изменения к проектной декларации в 120 квартале от 27-07-15",
    subtitle: "Опубликовано 27 июля 2015",
    company: 1,
    file: "files/NSK/nsk_120_27-07-15.zip",
  },
  {
    id: 31,
    title: "31.Изменения к проектной декларации в 120 квартале от 29-10-15",
    subtitle: "Опубликовано 29 октября 2015",
    company: 1,
    file: "files/NSK/nsk_120_29-10-15.zip",
  },
  {
    id: 32,
    title: "32.Изменения к проектной декларации в 120 квартале от 20-02-16",
    subtitle: "Опубликовано 20 февраля 2016",
    company: 1,
    file: "files/NSK/nsk_120_20-02-16.zip",
  },
  {
    id: 33,
    title: "33.Изменения к проектной декларации в 120 квартале от 24-03-16",
    subtitle: "Опубликовано 24 марта 2016",
    company: 1,
    file: "files/NSK/nsk_120_24-03-16.zip",
  },
  {
    id: 34,
    title: "34.ПРОЕКТНАЯ ДЕКЛАРАЦИЯ в 120 квартале Литер 2 от 25-03-16  ",
    subtitle: "Опубликовано 25 марта 2016",
    company: 1,
    file: "files/NSK/NSK_D_25-03-16.zip",
  },
  {
    id: 35,
    title: "35.Изменения к проектной декларации в 120 квартале Литер 2 от 20-04-16",
    subtitle: "Опубликовано 20 апреля 2016",
    company: 1,
    file: "files/NSK/nsk_120_20-04-16.zip",
  },
  {
    id: 36,
    title: "36.Изменения к проектной декларации в 120 квартале от 27-04-16",
    subtitle: "Опубликовано 27 апреля 2016",
    company: 1,
    file: "files/NSK/nsk_120_27-04-16.zip",
  },
  {
    id: 37,
    title: "37.Изменения к проектной декларации в 120 квартале Литер 2 от 27-04-16",
    subtitle: "Опубликовано 27 апреля 2016",
    company: 1,
    file: "files/NSK/nsk_120-l2_20-04-16.zip",
  },
  {
    id: 38,
    title: "38.Изменения к проектной декларации в 120 квартале от 26-07-16",
    subtitle: "Опубликовано 26 июля 2016",
    company: 1,
    file: "files/NSK/nsk_120_26-07-16.zip",
  },
  {
    id: 39,
    title: "39.Изменения к проектной декларации в 120 квартале Литер 2 от 26-07-16",
    subtitle: "Опубликовано 26 июля 2016",
    company: 1,
    file: "files/NSK/nsk_120-l2_26-07-16.zip",
  },
  {
    id: 40,
    title: "40.Изменения к проектной декларации в 120 квартале от 27-10-16",
    subtitle: "Опубликовано 27 октября 2016",
    company: 1,
    file: "files/NSK/nsk_120_27-10-16.zip",
  },
  {
    id: 41,
    title: "41.Изменения к проектной декларации в 120 квартале Литер 2 от 27-10-16",
    subtitle: "Опубликовано 27 октября 2016",
    company: 1,
    file: "files/NSK/nsk_120-l2_27-10-16.zip",
  },
  {
    id: 42,
    title: "42.Изменения к проектной декларации в 120 квартале Литер 2 от 09-01-17",
    subtitle: "Опубликовано 9 января 2017",
    company: 1,
    file: "files/NSK/nsk_120-l2_09-01-17.zip",
  },
  {
    id: 43,
    title: "43.Изменения к проектной декларации в 120 квартале от 22-03-17",
    subtitle: "Опубликовано 22 марта 2017",
    company: 1,
    file: "files/NSK/nsk_120_22-03-17.zip",
  },
  {
    id: 44,
    title: "44.Изменения к проектной декларации в 120 квартале Литер 2 от 22-03-17",
    subtitle: "Опубликовано 22 марта 2017",
    company: 1,
    file: "files/NSK/nsk_120-l2_22-03-17.zip",
  },
  {
    id: 45,
    title: "45.Изменения к проектной декларации в 120 квартале от 17-04-17",
    subtitle: "Опубликовано 17 апреля 2017",
    company: 1,
    file: "files/NSK/nsk_120_17-04-17.zip",
  },
  {
    id: 46,
    title: "46.Изменения к проектной декларации в 120 квартале Литер 2 от 17-04-17",
    subtitle: "Опубликовано 17 апреля 2017",
    company: 1,
    file: "files/NSK/nsk_120-l2_17-04-17.zip",
  },
  {
    id: 47,
    title: "47.Изменения к проектной декларации в 120 квартале от 26-04-17",
    subtitle: "Опубликовано 26 апреля 2017",
    company: 1,
    file: "files/NSK/nsk_120_26-04-17.zip",
  },
  {
    id: 48,
    title: "48.Изменения к проектной декларации в 120 квартале Литер 2 от 26-04-17",
    subtitle: "Опубликовано 26 апреля 2017",
    company: 1,
    file: "files/NSK/nsk_120-l2_26-04-17.zip",
  },
  {
    id: 49,
    title: "49.Изменения к проектной декларации в 120 квартале от 01-06-17",
    subtitle: "Опубликовано 1 июня 2017",
    company: 1,
    file: "files/NSK/nsk_120_01-06-17.zip",
  },
  {
    id: 50,
    title: "50.Изменения к проектной декларации в 120 квартале от 25-07-17",
    subtitle: "Опубликовано 25 июля 2017",
    company: 1,
    file: "files/NSK/nsk_120_25-07-17.zip",
  },
  {
    id: 51,
    title: "51.Изменения к проектной декларации в 120 квартале Литер 2 от 25-07-17",
    subtitle: "Опубликовано 25 июля 2017",
    company: 1,
    file: "files/NSK/nsk_120-l2_25-07-17.zip",
  },
  {
    id: 52,
    title: "52.Изменения к проектной декларации в 120 квартале Литер 2 от 26-10-17",
    subtitle: "Опубликовано 26 октября 2017",
    company: 1,
    file: "files/NSK/nsk_120-l2_26-10-17.zip",
  },
  {
    id: 53,
    title: "53.Изменения к проектной декларации в 120 квартале Литер 2 от 03-11-17",
    subtitle: "Опубликовано 3 ноября 2017",
    company: 1,
    file: "files/NSK/nsk_120-l2_03-11-17.zip",
  },
  {
    id: 54,
    title: "54.Изменения к проектной декларации в 120 квартале Литер 2 от 10-01-18",
    subtitle: "Опубликовано 10 января 2018",
    company: 1,
    file: "files/NSK/nsk_120-l2_10-01-18.zip",
  },
  {
    id: 55,
    title: "55.Изменения к проектной декларации в 120 квартале Литер 2 от 07-03-18",
    subtitle: "Опубликовано 3 марта 2018",
    company: 1,
    file: "files/NSK/nsk_120-l2_07-03-18.zip",
  },
  {
    id: 56,
    title: "56.Изменения к проектной декларации в 120 квартале Литер 2 от 24-04-18",
    subtitle: "Опубликовано 24 апреля 2018",
    company: 1,
    file: "files/NSK/nsk_120-l2_24-04-18.zip",
  },
  {
    id: 57,
    title: "57.Изменения к проектной декларации в 120 квартале Литер 2 от 01-06-18",
    subtitle: "Опубликовано 1 июня 2018",
    company: 1,
    file: "files/NSK/nsk_120-l2_01-06-18.zip",
  },
  {
    id: 58,
    title: "58.Изменения к проектной декларации в 120 квартале Литер 2 от 25-07-18",
    subtitle: "Опубликовано 25 июля 2018",
    company: 1,
    file: "files/NSK/nsk_120-l2_25-07-18.zip",
  },
  {
    id: 59,
    title: "59.ПРОЕКТНАЯ ДЕКЛАРАЦИЯ в 120 квартале Литер 3 от 26-09-18 ",
    subtitle: "Опубликовано 26 сентября 2018",
    company: 1,
    file: "files/NSK/NSK_D_26-09-18.zip",
  },
  {
    id: 60,
    title: "60.Изменения к проектной декларации в 120 квартале Литер 2 от 29-10-18",
    subtitle: "Опубликовано 29 октября 2018",
    company: 1,
    file: "files/NSK/nsk_120-l2_29-10-18.zip",
  },
  {
    id: 61,
    title: "61.Изменения к проектной декларации в 120 квартале Литер 3 от 29-10-18",
    subtitle: "Опубликовано 29 октября 2018",
    company: 1,
    file: "files/NSK/nsk_120-l3_29-10-18.zip",
  },
  {
    id: 62,
    title: "62.ПРОЕКТНАЯ ДЕКЛАРАЦИЯ в 425 квартале Литер 1 от 29-03-19",
    subtitle: "Опубликовано 29 марта 2019",
    company: 1,
    file: "files/NSK/NSK_D425_29-03-19.zip",
  },


  {
    id: 1001,
    title: "1.Изменения к проектной декларации в 150 квартале от 08-04-10",
    subtitle: "Опубликовано 8 апреля 2010",
    company: 2,
    file: "files/ASZ/14-04-10.zip",
  },
  {
    id: 1002,
    title: "2.Изменения к проектной декларации в 150 квартале от 09-08-10",
    subtitle: "Опубликовано 9 августа 2010",
    company: 2,
    file: "files/ASZ/10-08-10.zip",
  },
  {
    id: 1003,
    title: "3.Изменения к проектной декларации в 150 квартале от 12-10-10",
    subtitle: "Опубликовано 12 октября 2010",
    company: 2,
    file: "files/ASZ/13-10-10.zip",
  },
  {
    id: 1004,
    title: "4.ПРОЕКТНАЯ ДЕКЛАРАЦИЯ 150 квартал (2 очередь) от 07-02-11",
    subtitle: "Опубликовано 7 февраля 2011",
    company: 2,
    file: "files/ASZ/ASZ_D_150_2.zip",
  },
  {
    id: 1005,
    title: "5.Изменения к проектной декларации в 74 квартале ( надземная автостоянка) от 15-04-11",
    subtitle: "Опубликовано 15 апреля 2011",
    company: 2,
    file: "files/ASZ/1_15.04.2011.zip",
  },
  {
    id: 1006,
    title: "6.Изменения к проектной декларации в 150 квартале (2 очередь) от 15-04-11",
    subtitle: "Опубликовано 15 апреля 2011",
    company: 2,
    file: "files/ASZ/13-04-11_150-2.zip",
  },
  {
    id: 1007,
    title: "7.Изменения к проектной декларации в 74 квартале ( надземная автостоянка) от 14-07-11",
    subtitle: "Опубликовано 14 июля 2011",
    company: 2,
    file: "files/ASZ/14-07-11.zip",
  },
  {
    id: 1008,
    title: "8.Изменения к проектной декларации в 150 квартале (2 очередь) от 14-07-11",
    subtitle: "Опубликовано 14 июля 2011",
    company: 2,
    file: "files/ASZ/14-07-11_150-2.zip",
  },
  {
    id: 1009,
    title: "9.Изменения к проектной декларации в 150 квартале (2 очередь) от 19-10-11",
    subtitle: "Опубликовано 19 октября 2011",
    company: 2,
    file: "files/ASZ/19-10-12_150-2.zip",
  },
  {
    id: 1010,
    title: "10.Изменения к проектной декларации в 74 квартале ( надземная автостоянка) от 27-10-11",
    subtitle: "Опубликовано 27 октября 2011",
    company: 2,
    file: "files/ASZ/27-10-11.ZIP",
  },
  {
    id: 1011,
    title: "11.Изменения к проектной декларации в 150 квартале (2 очередь) от 27-10-11",
    subtitle: "Опубликовано 27 октября 2011",
    company: 2,
    file: "files/ASZ/27-10-12_150-2.zip",
  },
  {
    id: 1012,
    title: "12.ПРОЕКТНАЯ ДЕКЛАРАЦИЯ на строительство надземной автостоянки закрытого типа на 18 боксов в 74 квартале от 05-12-11",
    subtitle: "Опубликовано 5 декабря 2012",
    company: 2,
    file: "files/ASZ/07-12-11.zip",
  },
  {
    id: 1013,
    title: "13.Изменения к проектной декларации в 150 квартале (2 очередь) от 31-01-12",
    subtitle: "Опубликовано 31 января 2012",
    company: 2,
    file: "files/ASZ/30-01-12_150-2.zip",
  },
  {
    id: 1014,
    title: "14.Изменения к проектной декларации в 74 квартале (надземная автостоянка) от 30-01-12",
    subtitle: "Опубликовано 30 января 2012",
    company: 2,
    file: "files/ASZ/30-01-12_74.zip",
  },
  {
    id: 1015,
    title: "15.Изменения к проектной декларации в 74 квартале ( надземная автостоянка) от 25-04-12",
    subtitle: "Опубликовано 25 апреля 2012",
    company: 2,
    file: "files/ASZ/29-05-12.zip",
  },
  {
    id: 1016,
    title: "16.Изменения к проектной декларации в 150 квартале (2 очередь) от 25-04-12",
    subtitle: "Опубликовано 25 апреля 2012",
    company: 2,
    file: "files/ASZ/29-05-12_74.zip",
  },
  {
    id: 1017,
    title: "17.Изменения к проектной декларации в 74 квартале ( надземная автостоянка) от 03-07-12",
    subtitle: "Опубликовано 3 июля 2012",
    company: 2,
    file: "files/ASZ/03-07-12.zip",
  },
  {
    id: 1018,
    title: "18.Изменения к проектной декларации в 150 квартале (2 очередь) от 18-07-12",
    subtitle: "Опубликовано 18 июля 2012",
    company: 2,
    file: "files/ASZ/7_18.07.2012.zip",
  },
  {
    id: 1019,
    title: "19.Изменения к проектной декларации в 74 квартале ( надземная автостоянка) от 19-07-12",
    subtitle: "Опубликовано 19 июля 2012",
    company: 2,
    file: "files/ASZ/ASZ_74_5.zip",
  },
  {
    id: 1020,
    title: "20.Изменения к проектной декларации в 150 квартале (2 очередь) от 23-10-12",
    subtitle: "Опубликовано 23 октября 2012",
    company: 2,
    file: "files/ASZ/asz241012.zip",
  },
  {
    id: 1021,
    title: "21.ПРОЕКТНАЯ ДЕКЛАРАЦИЯ 150 квартал (3 очередь) от 06-11-12",
    subtitle: "Опубликовано 6 ноября 2012",
    company: 2,
    file: "files/ASZ/Dekl_150_3_och.zip",
  },
  {
    id: 1022,
    title: "22.Изменения к проектной декларации 150 квартал (3 очередь) от 20-12-12",
    subtitle: "Опубликовано 12 декабря 2012",
    company: 2,
    file: "files/ASZ/ASZ_D_150_3.zip",
  },
  {
    id: 1023,
    title: "23.Изменения к проектной декларации в 150 квартале (3 очередь) от 26-03-13",
    subtitle: "Опубликовано 26 марта 2013",
    company: 2,
    file: "files/ASZ/asz_26-03-13.zip",
  },
  {
    id: 1024,
    title: "24.Изменения к проектной декларации в 150 квартале (3 очередь) от 15-05-13",
    subtitle: "Опубликовано 15 мая 2013",
    company: 2,
    file: "files/ASZ/ASZ_15-05-13.zip",
  },
  {
    id: 1025,
    title: "25.Изменения к проектной декларации в 150 квартале (3 очередь) от 23-05-13",
    subtitle: "Опубликовано 23 мая 2013",
    company: 2,
    file: "files/ASZ/asz23-05-13.zip",
  },
  {
    id: 1026,
    title: "26.Изменения к проектной декларации в 150 квартале (3 очередь) от 10-06-13",
    subtitle: "Опубликовано 10 июня 2013",
    company: 2,
    file: "files/ASZ/150_10-06-13.zip",
  },
  {
    id: 1027,
    title: "27.Изменения к проектной декларации в 150 квартале (3 очередь) от 24-07-13",
    subtitle: "Опубликовано 24 июля 2013",
    company: 2,
    file: "files/ASZ/ASZ_24-07-13.zip",
  },
  {
    id: 1028,
    title: "28.Изменения к проектной декларации в 150 квартале (3 очередь) от 24-10-13",
    subtitle: "Опубликовано 24 октября 2013",
    company: 2,
    file: "files/ASZ/asz_24-10-13.zip",
  },
  {
    id: 1029,
    title: "29.Изменения к проектной декларации в 150 квартале (3 очередь) от 11-12-13",
    subtitle: "Опубликовано 11 декабря 2013",
    company: 2,
    file: "files/ASZ/asz_11-12-13.zip",
  },
  {
    id: 1030,
    title: "30.Изменения к проектной декларации в 150 квартале (3 очередь) от 28-02-14",
    subtitle: "Опубликовано 28 февраля 2014",
    company: 2,
    file: "files/ASZ/asz28-02-14.zip",
  },
  {
    id: 1031,
    title: "31.Изменения к проектной декларации в 150 квартале (3 очередь) от 21-03-14",
    subtitle: "Опубликовано 21 марта 2014",
    company: 2,
    file: "files/ASZ/asz_21-03-14.zip",
  },
  {
    id: 1032,
    title: "32.Изменения к проектной декларации в 150 квартале (3 очередь) от 25-04-14",
    subtitle: "Опубликовано 25 апреля 2014",
    company: 2,
    file: "files/ASZ/asz_25-04-14.zip",
  },
  {
    id: 1033,
    title: "33.Изменения к проектной декларации в 150 квартале (3 очередь) от 08-07-14",
    subtitle: "Опубликовано 8 июля 2014",
    company: 2,
    file: "files/ASZ/asz_08-07-14_3och.zip",
  },
  {
    id: 1034,
    title: "34.ПРОЕКТНАЯ ДЕКЛАРАЦИЯ в 150 квартале (4 очередь) от 08-07-14",
    subtitle: "Опубликовано 8 июля 2014",
    company: 2,
    file: "files/ASZ/Dekl_150_4_och.zip",
  },
  {
    id: 1035,
    title: "35.ПРОЕКТНАЯ ДЕКЛАРАЦИЯ в 34 квартале от 14-08-14",
    subtitle: "Опубликовано 14 августа 2014",
    company: 2,
    file: "files/ASZ/asz_deklar_34_14-08-14.zip",
  },
  {
    id: 1036,
    title: "36.Изменения к проектной декларации в 150 квартале (4 очередь) от 16-10-14",
    subtitle: "Опубликовано 16 октября 2014",
    company: 2,
    file: "files/ASZ/asz_16-10-14.zip",
  },
  {
    id: 1037,
    title: "37.Изменения к проектной декларации в 34 квартале от 28-10-14",
    subtitle: "Опубликовано 28 октября 2014",
    company: 2,
    file: "files/ASZ/asz_34_28-10-14.zip",
  },
  {
    id: 1038,
    title: "38.Изменения к проектной декларации в 150 квартале (3 очередь) от 28-10-14",
    subtitle: "Опубликовано 28 октября 2014",
    company: 2,
    file: "files/ASZ/asz_150(3)_28-10-14.zip",
  },
  {
    id: 1039,
    title: "39.Изменения к проектной декларации в 150 квартале (4 очередь) от 28-10-14",
    subtitle: "Опубликовано 28 октября 2014",
    company: 2,
    file: "files/ASZ/asz_150(4)_28-10-14.zip",
  },
  {
    id: 1040,
    title: "40.Изменения к проектной декларации в 150 квартале (4 очередь) от 25-11-14",
    subtitle: "Опубликовано 25 ноября 2014",
    company: 2,
    file: "files/ASZ/asz_150(4)_25-11-14.zip",
  },
  {
    id: 1041,
    title: "41.Изменения к проектной декларации в 34 квартале от 25-11-14",
    subtitle: "Опубликовано 25 ноября 2014",
    company: 2,
    file: "files/ASZ/asz_34_25-11-14.zip",
  },
  {
    id: 1042,
    title: "42.Изменения к проектной декларации в 150 квартале (4 очередь) от 05-12-14",
    subtitle: "Опубликовано 5 декабря 2014",
    company: 2,
    file: "files/ASZ/asz_150(4)_05-12-14.zip",
  },
  {
    id: 1043,
    title: "43.Изменения к проектной декларации в 34 квартале от 24-03-15",
    subtitle: "Опубликовано 24 марта 2015",
    company: 2,
    file: "files/ASZ/asz_34_24-03-15.zip",
  },
  {
    id: 1044,
    title: "44.Изменения к проектной декларации в 150 квартале (4 очередь) от 24-03-15",
    subtitle: "Опубликовано 24 марта 2015",
    company: 2,
    file: "files/ASZ/asz_150(4)_24-03-15.zip",
  },
  {
    id: 1045,
    title: "45.Изменения к проектной декларации в 34 квартале от 28-04-15 ",
    subtitle: "Опубликовано 28 апреля 2015",
    company: 2,
    file: "files/ASZ/asz_34_28-04-15.zip",
  },
  {
    id: 1046,
    title: "46.Изменения к проектной декларации в 150 квартале (4 очередь) от 28-04-15",
    subtitle: "Опубликовано 28 апреля 2015",
    company: 2,
    file: "files/ASZ/asz_150(4)_28-04-15.zip",
  },
  {
    id: 1047,
    title: "47.Изменения к проектной декларации в 34 квартале от 19-05-15",
    subtitle: "Опубликовано 19 мая 2015",
    company: 2,
    file: "files/ASZ/asz_34_19-05-15.zip",
  },
  {
    id: 1048,
    title: "48.Изменения к проектной декларации в 150 квартале (4 очередь) от 19-05-15",
    subtitle: "Опубликовано 19 мая 2015",
    company: 2,
    file: "files/ASZ/asz_150(4)_19-05-15.zip",
  },
  {
    id: 1049,
    title: "49.Изменения к проектной декларации в 34 квартале от 27-07-15",
    subtitle: "Опубликовано 27 июля 2015",
    company: 2,
    file: "files/ASZ/asz_34_27-07-15.zip",
  },
  {
    id: 1050,
    title: "50.Изменения к проектной декларации в 150 квартале (4 очередь) от 27-07-15",
    subtitle: "Опубликовано 27 июля 2015",
    company: 2,
    file: "files/ASZ/asz_150(4)_27-07-15.zip",
  },
  {
    id: 1051,
    title: "51.Изменения к проектной декларации в 150 квартале (4 очередь) от 17-08-15",
    subtitle: "Опубликовано 17 августа 2015",
    company: 2,
    file: "files/ASZ/asz_150(4)_17-08-15.zip",
  },
  {
    id: 1052,
    title: "52.Изменения к проектной декларации в 34 квартале от 01-10-15",
    subtitle: "Опубликовано 1 октября 2015",
    company: 2,
    file: "files/ASZ/asz_34_01-10-15.zip",
  },
  {
    id: 1053,
    title: "53.Изменения к проектной декларации в 150 квартале (4 очередь) от 29-10-15",
    subtitle: "Опубликовано 29 октября 2015",
    company: 2,
    file: "files/ASZ/asz_150(4)_29-10-15.zip",
  },
  {
    id: 1054,
    title: "54.Изменения к проектной декларации в 34 квартале от 29-10-15",
    subtitle: "Опубликовано 29 октября 2015",
    company: 2,
    file: "files/ASZ/asz_34_29-10-15.zip",
  },
  {
    id: 1055,
    title: "55.Изменения к проектной декларации в 34 квартале от 13-11-15",
    subtitle: "Опубликовано 13 ноября 2015",
    company: 2,
    file: "files/ASZ/asz_34_13-11-15.zip",
  },
  {
    id: 1056,
    title: "56.Изменения к проектной декларации в 34 квартале от 10-12-15 ",
    subtitle: "Опубликовано 12 декабря 2015",
    company: 2,
    file: "files/ASZ/asz_34_10-12-15.zip",
  },
  {
    id: 1057,
    title: "57.Изменения к проектной декларации в 34 квартале от 10-03-16",
    subtitle: "Опубликовано 10 марта 2016",
    company: 2,
    file: "files/ASZ/asz_34_10-03-16.zip",
  },
  {
    id: 1058,
    title: "58.Изменения к проектной декларации в 34 квартале от 24-03-16",
    subtitle: "Опубликовано 24 марта 2016",
    company: 2,
    file: "files/ASZ/asz_34_24-03-16.zip",
  },
  {
    id: 1059,
    title: "59.Изменения к проектной декларации в 34 квартале от 27-04-16",
    subtitle: "Опубликовано 27 апреля 2016",
    company: 2,
    file: "files/ASZ/asz_34_27-04-16.zip",
  },
  {
    id: 1060,
    title: "60.Изменения к проектной декларации в 34 квартале от 26-07-16",
    subtitle: "Опубликовано 26 июля 2016",
    company: 2,
    file: "files/ASZ/asz_34_26-07-16.zip",
  },
  {
    id: 1061,
    title: "61.Изменения к проектной декларации в 34 квартале от 27-10-16",
    subtitle: "Опубликовано 27 октября 2016",
    company: 2,
    file: "files/ASZ/asz_34_27-10-16.zip",
  },


];


export default docDeclarationBD;