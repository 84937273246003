import React, { useContext, useEffect, useState } from "react";
import MKBox from "../components/MKBox";
import DefaultFooter from "../examples/Footers/DefaultFooter";
import footerRoutes from "../footer.routes";
import DefaultNavbar from "../examples/Navbars/DefaultNavbar";
import routes from "../routes";
import Container from "@mui/material/Container";
import ProductImage from "../components/ProductImage";
import Grid from "@mui/material/Grid";
import Card from "@mui/material/Card";
import TabsObject from "../components/TabsObject";
import { useParams } from "react-router-dom";
import objectsBD from "../data/objectsBD";
import TableLiterBody from "../components/TableLiterBody";
import BasicTabs from "../components/BasicTabs";
import axios from "axios";
import { AuthContext } from "../context";


const ObjectIdPage = () => {
    const { id } = useParams();
    const object = objectsBD.find(el => el.id === Number(id));

    const [entrances, setEntrances] = useState("");
    const [paramTabs, setParamTabs] = useState([]);
    const { isLoaded, setIsLoaded } = useContext(AuthContext);

    async function getData() {
      const response = await axios.get(`https://nsk.aszblag.ru/table.php?id=${id}`);
      setEntrances(response.data);
      setIsLoaded(true);
    }

    if (!isLoaded || !entrances) {
      getData();
    }

    useEffect(() => {

      if (entrances) {
        const paramTabs = [];
        entrances.map((entrance, index) => {
          if (entrance.floors.length > 1) {
            return (
              paramTabs.push(
                {
                  label: `Подъезд ${entrance.entranceID}`,
                  content: <TableLiterBody floors={entrance.floors} entrance={entrance.entranceID} house={id}
                                           key={index}/>,
                },
              )
            );
          }
        });
        setParamTabs(paramTabs);
      }
    }, [entrances]);

    return (
      <div>

        <DefaultNavbar
          routes={routes}
        />

        <div style={{ height: "100px" }}/>
        <Card
          sx={{
            p: 2,
            mx: { xs: 2, lg: 3 },
            mt: -8,
            mb: 4,
            boxShadow: ({ boxShadows: { xxl } }) => xxl,
          }}
        >

          <Container>
            <MKBox component="section" pt={6} my={6}>

              <Grid container spacing={3} alignItems="top">
                {
                  ((object.office !== undefined) && object.office)
                    ? (
                      <Grid item xs={12} md={12} sx={{ mb: 2 }}>
                        <div style={{ textAlign: "center" }}>
                          <h1>{object.title}</h1>
                          {object.desc}
                        </div>
                      </Grid>
                    )
                    : (
                      <Grid container spacing={3} alignItems="top">
                        <Grid item xs={12} md={6} sx={{ mb: 2 }}>
                          <ProductImage id={id}/>
                        </Grid>
                        <Grid item xs={12} md={6} sx={{ mb: 2 }}>
                          <div style={{ textAlign: "center" }}>
                            <h1>{object.title}</h1>
                            {object.desc}
                          </div>
                        </Grid>
                      </Grid>
                    )
                }

                <Grid item xs={12} md={12} sx={{ mb: 2 }}>
                  <div>
                    <div className="product-desc-full">
                      <TabsObject id={id}/>
                    </div>
                  </div>
                </Grid>
                {(paramTabs.length > 0) ?
                  (
                    <Grid item xs={12} md={12} sx={{ mb: 2 }}>
                      <BasicTabs params={paramTabs} border={true}/>
                    </Grid>
                  )
                  : null

                }
              </Grid>
            </MKBox>
          </Container>
        </Card>


        <MKBox pt={6} px={1} mt={6}>
          <DefaultFooter content={footerRoutes}/>
        </MKBox>
      </div>
    );
  }
;

export default ObjectIdPage;