import React from "react";

const Popup = ({active, SetActive, children}) => {
  return (
    <div className={active ? "modal active" : "modal"} onClick={()=> {
      console.log("click");
      SetActive([])
    }}>
      <div className={active ? "modal__content active" : "modal__content"} onClick={e => e.stopPropagation()}>
        {children}
      </div>
    </div>
  );
};

export default Popup;