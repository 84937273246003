/*
=========================================================
* Material Kit 2 React - v2.0.0
=========================================================

* Product Page: https://www.creative-tim.com/product/material-kit-react
* Copyright 2021 Creative Tim (https://www.creative-tim.com)

Coded by www.creative-tim.com

 =========================================================

* The above copyright notice and this permission notice shall be included in all copies or substantial portions of the Software.
*/

// prop-types is a library for typechecking of props
import PropTypes from "prop-types";
// @mui material components
// Material Kit 2 React components
import MKBox from "components/MKBox";
import MKTypography from "components/MKTypography";

function ObjectCard({ images, name, desc, ...rest }) {
  const image = require(`assets/${images[0]}`).default !== undefined
    ? require(`assets/${images[0]}`).default
    : require(`assets/images/noImage.png`).default;

  const imageTemplate = (
    <MKBox
      bgColor="white"
      borderRadius="xl"
      shadow="lg"
      minHeight="10rem"
      sx={{
        overflow: "hidden",
        transform: "perspective(999px) rotateX(0deg) translate3d(0, 0, 0)",
        transformOrigin: "50% 0",
        backfaceVisibility: "hidden",
        willChange: "transform, box-shadow",
        transition: "transform 200ms ease-out",

        "&:hover": {
          transform: "perspective(999px) rotateX(5deg) translate3d(0px, -4px, 5px)",
        },
      }}
      {...rest}
    >

      <MKBox
        component="img"
        src={image}
        alt={name}
        width="100%"
        my="auto"
        opacity={1}
      />
    </MKBox>
  );
  //console.log(`assets/images${images[0]}`);
  return (
    <MKBox position="relative">
      {imageTemplate}
      {name || desc ? (
        <MKBox mt={1} ml={1} lineHeight={1}>
          {name && (
            <MKTypography variant="h6" fontWeight="bold">
              {name}
            </MKTypography>
          )}
          <MKTypography variant="button" fontWeight="regular" color="secondary">
            <div style={{height: "120px" }}>
              {desc}
            </div>
          </MKTypography>
        </MKBox>
      ) : null}
    </MKBox>
  );
}

// Setting default props for the ObjectCard
ObjectCard.defaultProps = {
  name: "",
  desc: "",
};

// Typechecking props for the ObjectCard
ObjectCard.propTypes = {
  image: PropTypes.string.isRequired,
  name: PropTypes.string,
  desc: PropTypes.string,
};

export default ObjectCard;
