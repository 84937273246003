import React, { useEffect, useState } from "react";
import ImageGallery from "react-image-gallery";
import objectsBD from "../data/objectsBD";

const ProductImage = ({id}) => {
  const [images, setImages] = useState([]);
  useEffect(() => {
    const object = objectsBD.find(el=>el.id==Number(id));
    const imgs = object.images.map(el => {
      return {
        original: require(`assets/${el}`).default,
        thumbnail: require(`assets/${el}`).default,
      };
    });
    setImages(imgs);
  }, []);
  return (
    <div>
      <ImageGallery items={images}/>
    </div>
  );
};

export default ProductImage;