import React from "react";
import MKBox from "./MKBox";
import MKTypography from "./MKTypography";

const BlockInfo = (props) => {
  return (
    <MKBox
      bgColor="white"
      borderRadius="xl"
      shadow="lg"
      display="flex"
      flexDirection="column"
      justifyContent="center"
      mt={props.mt}
      mb={props.mb}
      mx={3}
    >
      {
        (props.info.title !== undefined)
          ? (
            <MKBox
              variant="gradient"
              bgColor="info"
              coloredShadow="info"
              borderRadius="lg"
              p={2}
              mx={2}
              mt={-3}
            >
              <MKTypography variant={(props.info.titleFontSize !== undefined) ? (props.info.titleFontSize) : "h6"}
                            color="white">
                {props.info.title}
              </MKTypography>
            </MKBox>
          )
          : null
      }
      <MKBox p={3}>
        {props.info.content}
      </MKBox>
    </MKBox>
  );
};

export default BlockInfo;