const categoryObjectsBD = [
  {
    id: 1,
    title: "Информация о квартирах и офисах",
    image: "images/objects/category_new.jpg",
    route: "/objects-new",
  },
  {
    id: 2,
    title: "Сданные объекты",
    image: "images/objects/category_build.jpg",
    route: "/objects-build",
  },

];


export default categoryObjectsBD;