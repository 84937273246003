import newsBD from "../data/newsBD";

export const GetDataService =(count,page)=> {
    let fetchingData = newsBD;
    if (count) {
      fetchingData = newsBD.slice(0, count);
    }
    if (page) {
      fetchingData = newsBD.slice((page-1)*count, page*count);
    }

    return fetchingData;
  }



