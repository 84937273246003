import React, { useEffect, useMemo, useRef, useState } from "react";
import Card from "@mui/material/Card";
import Container from "@mui/material/Container";
import MKBox from "../components/MKBox";
import Grid from "@mui/material/Grid";
import DefaultFooter from "../examples/Footers/DefaultFooter";
import footerRoutes from "../footer.routes";
import DefaultNavbar from "../examples/Navbars/DefaultNavbar";
import routes from "../routes";
import newsBD from "../data/newsBD";
import objectsBD from "../data/objectsBD";
import FetchNews from "../components/FetchNews";
import MKTypography from "../components/MKTypography";
import { GetDataService } from "../API/GetDataService";


const NewsPage = () => {
  const objects = objectsBD.filter(el => el.new == true);
  const lastElement = useRef();
  const observer = useRef();
  const [posts, setPosts] = useState([]);
  const [page, setPage] = useState(1);

  useEffect(()=>{
    const response = GetDataService(10, page);
    setPosts([...posts, ...response]);
  },[page])

  useEffect(() => {
    if (observer.current) observer.current.disconnect();
    var callback = function(entries, observer) {
      if (entries[0].isIntersecting) {
        setPage(page + 1);
        console.log("Div в зоне видимости");
        console.log(page);
      }
    };
    observer.current = new IntersectionObserver(callback);
    observer.current.observe(lastElement.current);
  }, [page]);


  // const [fetchPosts, isPostsLoading, postError] = useFetching(async (limit, page) => {
  //   const response = DataService.getData(10,1);
  //   setPosts([...posts, ...response.data]);
  //   const totalCount = response.headers['x-total-count'];
  //   setTotalPages(getCount(totalCount, limit));
  // })


  return (
    <>
      <DefaultNavbar
        routes={routes}
      />
      <div style={{ height: "100px" }}/>
      <Card
        sx={{
          p: 2,
          mx: { xs: 2, lg: 3 },
          mt: -8,
          mb: 4,
          boxShadow: ({ boxShadows: { xxl } }) => xxl,
        }}
      >
        <div style={{ height: "50px" }}/>

        <Container>
          <Grid container alignItems="flex-start">
            <MKTypography variant="h3" fontWeight="bold" style={{ marginBottom: "10px" }}>
              НОВОСТИ
            </MKTypography>
            <FetchNews xs={12} md={12} posts={posts}/>
            <div ref={lastElement} style={{ height: 1, width: "100%", background: "red" }}/>
          </Grid>
        </Container>

      </Card>
      <Card
        sx={{
          p: 2,
          mx: { xs: 2, lg: 3 },
          mt: -8,
          mb: 4,
          boxShadow: ({ boxShadows: { xxl } }) => xxl,
        }}
      >

        <Container>
          <MKBox component="section" pt={6} my={6}>
            <Grid container spacing={3} alignItems="center">
            </Grid>
          </MKBox>
        </Container>
      </Card>

      <MKBox pt={6} px={1} mt={6}>
        <DefaultFooter content={footerRoutes}/>
      </MKBox>
    </>
  );
};

export default NewsPage;