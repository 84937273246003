import React from "react";
import DefaultNavbar from "../examples/Navbars/DefaultNavbar";
import routes from "../routes";
import Card from "@mui/material/Card";
import Container from "@mui/material/Container";
import Grid from "@mui/material/Grid";
import MKTypography from "../components/MKTypography";
import MKBox from "../components/MKBox";
import DefaultFooter from "../examples/Footers/DefaultFooter";
import footerRoutes from "../footer.routes";
import ContactForm from "../components/ContactForm";
import BlockInfo from "../components/BlockInfo";
import { Map, Placemark, YMaps } from "react-yandex-maps";
import BasicTabs from "../components/BasicTabs";
import FetchDocs from "../components/FetchDocs";
import documentationBD from "../data/documentationBD";

const addRow = (props) => {
  return (
    <MKTypography variant="h6" fontWeight="bold" marginBottom="10px">
      <span style={{ marginRight: "10px" }}>{props.label}</span>
      <span style={{ color: "#358ee9" }}>{props.value}</span>
    </MKTypography>
  );

};

const ContactsPage = () => {
  const contacts = [
    {
      title: "ОБЩЕСТВО С ОГРАНИЧЕННОЙ ОТВЕТСТВЕННОСТЬЮ СПЕЦИАЛИЗИРОВАННЫЙ ЗАСТРОЙЩИК \"АМУРСТРОЙЗАКАЗЧИК\"",
      titleFontSize: "h5",
      content:
        <div>
          {addRow({
            label: "Адрес:",
            value: "675000 Амурская область г.Благовещенск ул.Горького 152",
          })}
          {addRow({
            label: "Генеральный директор:",
            value: "Лагутина Маргарита Анатольевна",
          })}
          {addRow({
            label: "Телефон:",
            value: "8(4162)49-10-99, 8(4162)52-58-85",
          })}
          {addRow({
            label: "Отдел продаж:",
            value: "8-914-384-78-68",
          })}
          {addRow({
            label: "Факс:",
            value: "8(4162)52-39-55",
          })}
          {addRow({
            label: "E-mail:",
            value: "aszblag@mail.ru",
          })}
          {addRow({
            label: "Сайт:",
            value: "nsk.aszblag.ru",
          })}
        </div>,
    },
    {
      title: "ОБЩЕСТВО С ОГРАНИЧЕННОЙ ОТВЕТСТВЕННОСТЬЮ СПЕЦИАЛИЗИРОВАННЫЙ ЗАСТРОЙЩИК \"НАРОДНАЯ СТРОИТЕЛЬНАЯ КОМПАНИЯ\"",
      titleFontSize: "h5",
      content:
        <div>
          {addRow({
            label: "Адрес:",
            value: "675000 Амурская область г.Благовещенск ул.Горького 152",
          })}
          {addRow({
            label: "Генеральный директор:",
            value: "Лагутина Маргарита Анатольевна",
          })}
          {addRow({
            label: "Телефон:",
            value: "8(4162)49-10-99, 8(4162)52-58-85",
          })}
          {addRow({
            label: "Отдел продаж:",
            value: "8-914-384-78-68",
          })}
          {addRow({
            label: "Факс:",
            value: "8(4162)52-39-55",
          })}
          {addRow({
            label: "E-mail:",
            value: "aszblag@mail.ru",
          })}
          {addRow({
            label: "Сайт:",
            value: "nsk.aszblag.ru",
          })}
        </div>,
    },
    {
      title: "ОБЩЕСТВО С ОГРАНИЧЕННОЙ ОТВЕТСТВЕННОСТЬЮ СПЕЦИАЛИЗИРОВАННЫЙ ЗАСТРОЙЩИК \"МОНТАЖЖИЛСТРОЙ\"",
      titleFontSize: "h5",
      content:
        <div>
          {addRow({
            label: "Адрес:",
            value: "675000 Амурская область г.Благовещенск ул.Горького 152",
          })}
          {addRow({
            label: "Генеральный директор:",
            value: "Лагутина Маргарита Анатольевна",
          })}
          {addRow({
            label: "Телефон:",
            value: "8(4162)49-10-99, 8(4162)52-58-85",
          })}
          {addRow({
            label: "Отдел продаж:",
            value: "8-914-384-78-68",
          })}
          {addRow({
            label: "Факс:",
            value: "8(4162)52-39-55",
          })}
          {addRow({
            label: "E-mail:",
            value: "aszblag@mail.ru",
          })}
          {addRow({
            label: "Сайт:",
            value: "nsk.aszblag.ru",
          })}
        </div>,
    },
  ];

  const paramTabs = [
    {
      label: "АМУРСТРОЙЗАКАЗЧИК",
      content: <BlockInfo info={contacts[0]} mt={{ xs: 20, sm: 18, md: 0 }} mb={{ xs: 20, sm: 18, md: 2 }}/>,
    },
    {
      label: "НСК",
      content: <BlockInfo info={contacts[1]} mt={{ xs: 20, sm: 18, md: 0 }} mb={{ xs: 20, sm: 18, md: 2 }}/>,
    },
    {
      label: "МОНТАЖЖИЛСТРОЙ",
      content: <BlockInfo info={contacts[2]} mt={{ xs: 20, sm: 18, md: 0 }} mb={{ xs: 20, sm: 18, md: 2 }}/>,
    },
  ];

  return (
    <>
      <DefaultNavbar
        routes={routes}
      />
      <div style={{ height: "100px" }}/>

      <Card
        sx={{
          p: 2,
          mx: { xs: 2, lg: 3 },
          mt: -8,
          mb: 4,
          boxShadow: ({ boxShadows: { xxl } }) => xxl,
        }}
      >

        <Container>
          <Grid className="Grid1" container alignItems="flex-start">
            <MKTypography variant="h3" fontWeight="bold" style={{ marginBottom: "20px", marginTop: "30px"}}>
              НАШИ КОНТАКТЫ
            </MKTypography>

            <BasicTabs params={paramTabs} />
            <YMaps>
              <Map defaultState={{ center: [50.265282, 127.538708], zoom: 15 }}
                   width="100%"
                   height="600px"
              >
                <Placemark
                  geometry={[50.265282, 127.538708]}
                  properties={{
                    iconCaption: "Амурстройзаказчик",
                  }}
                  options={{
                    iconColor: "blue",

                  }}
                />
              </Map>
            </YMaps>
            <Grid item xs={12} md={3}>
            </Grid>
            <Grid item xs={12} md={6}>
              <ContactForm/>
            </Grid>
            <Grid item xs={12} md={3}>
            </Grid>
          </Grid>


        </Container>


      </Card>


      <MKBox pt={6} px={1} mt={6}>
        <DefaultFooter content={footerRoutes}/>
      </MKBox>
    </>
  );

};


export default ContactsPage;