import React from "react";
import objectsBD from "../data/objectsBD";
import Card from "@mui/material/Card";
import Container from "@mui/material/Container";
import MKBox from "./MKBox";
import Grid from "@mui/material/Grid";
import { Link } from "react-router-dom";
import ObjectCard from "../pages/LandingPages/components/ObjectCard";
import DefaultFooter from "../examples/Footers/DefaultFooter";
import footerRoutes from "../footer.routes";
import DefaultNavbar from "../examples/Navbars/DefaultNavbar";
import routes from "../routes";


const Objects = (props) => {
  const fetchObjects = objectsBD.filter(el=>el.new==props.new);
  return (
    <>
      <DefaultNavbar
        routes={routes}
      />

      <div style={{ height: "100px" }}/>
      <Card
        sx={{
          p: 2,
          mx: { xs: 2, lg: 3 },
          mt: -8,
          mb: 4,
          boxShadow: ({ boxShadows: { xxl } }) => xxl,
        }}
      >

        <Container>

          <MKBox component="section" pt={6} my={6}>

            <Grid container spacing={3} alignItems="center">

              {fetchObjects.map((object) => (
                <Grid item xs={12} md={4} sx={{ mb: 2 }} key={object.id}>
                  <Link to={`/object/${object.id}`}>
                    <ObjectCard images={object.images} name={object.title} desc={object.desc} />
                  </Link>
                </Grid>
              ))}

            </Grid>
          </MKBox>


        </Container>

      </Card>

      <MKBox pt={6} px={1} mt={6}>
        <DefaultFooter content={footerRoutes}/>
      </MKBox>
    </>
  );
}

export default Objects;