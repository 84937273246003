/**
 =========================================================
 * Material Kit 2 React - v2.0.0
 =========================================================

 * Product Page: https://www.creative-tim.com/product/material-kit-react
 * Copyright 2021 Creative Tim (https://www.creative-tim.com)

 Coded by www.creative-tim.com

 =========================================================

 * The above copyright notice and this permission notice shall be included in all copies or substantial portions of the Software.
 */

import { useEffect, useState } from "react";
// react-router components
import { Navigate, Route, Routes, useLocation } from "react-router-dom";
// @mui material components
import { ThemeProvider } from "@mui/material/styles";
import CssBaseline from "@mui/material/CssBaseline";
// Material Kit 2 React themes
import theme from "assets/theme";
// Material Kit 2 React routes
import ObjectIdPage from "./pages/ObjectIdPage";
import "react-image-gallery/styles/css/image-gallery.css";
import "./styles/App.css";

import "lightgallery.js/dist/css/lightgallery.css";
import { LightgalleryProvider } from "react-lightgallery";
import Objects from "./components/Objects";
import StartPage from "./pages/StartPage";
import NewsPage from "./pages/NewsPage";
import NewsIdPage from "./pages/NewsIdPage";
import DeclarationPage from "./pages/DeclarationPage";
import ContactsPage from "./pages/ContactsPage";
import DocPage from "./pages/DocPage";
import Login from "./pages/Login";
import { AuthContext } from "./context";


export default function App() {
  const { pathname } = useLocation();

  // Setting page scroll to 0 when changing the route
  useEffect(() => {
    document.documentElement.scrollTop = 0;
    document.scrollingElement.scrollTop = 0;
  }, [pathname]);
  const [isAuth,setIsAuth] = useState(false);
  const [isLoaded,setIsLoaded] = useState(false);
  return (
    <AuthContext.Provider value={{
      isAuth,
      setIsAuth: setIsAuth,
      isLoaded,
      setIsLoaded: setIsLoaded,
    }}>
      <LightgalleryProvider>
        <ThemeProvider theme={theme}>
          <CssBaseline/>
          <Routes>
            <Route path="/" element={<StartPage/>}/>
            <Route exact path="/declaration" element={<DeclarationPage/>}/>
            <Route exact path="/documentation" element={<DocPage/>}/>
            <Route exact path="/news/:id" element={<NewsIdPage/>}/>
            <Route exact path="/object/:id" element={<ObjectIdPage/>}/>
            <Route exact path="/objects-new" element={<Objects new={true}/>}/>
            <Route exact path="/objects-build" element={<Objects new={false}/>}/>
            <Route exact path="/news" element={<NewsPage/>}/>
            <Route exact path="/contacts" element={<ContactsPage/>}/>
            <Route exact path="/login" element={<Login/>}/>
            <Route path="*" element={<Navigate to="/"/>}/>
          </Routes>
        </ThemeProvider>
      </LightgalleryProvider>
    </AuthContext.Provider>
  );
}
