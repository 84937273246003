// @mui icons
import FacebookIcon from "@mui/icons-material/Facebook";
import TwitterIcon from "@mui/icons-material/Twitter";
import GitHubIcon from "@mui/icons-material/GitHub";
import YouTubeIcon from "@mui/icons-material/YouTube";

// Material Kit 2 React components
import MKTypography from "components/MKTypography";

// Images
import logoCT from "assets/images/logo-ct-dark.png";

const date = new Date().getFullYear();

export default {
  brand: {
    name: "АСЗ",
    image: logoCT,
    route: "/",
  },
  copyright: (
    <MKTypography variant="button" fontWeight="regular">
      &copy; {date}. ООО "СЗ "Амурстройзаказчик". Все права защищены. 
    </MKTypography>
  ),
};
