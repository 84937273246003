import React from "react";
import newsBD from "../data/newsBD";
import Grid from "@mui/material/Grid";
import MKBox from "./MKBox";
import { Link } from "react-router-dom";
import iconDoc from "../assets/images/doc.png";
import MKTypography from "./MKTypography";
import "../styles/App.css";

const FetchNews = (props) => {
  const renderImage = (news) => {
    return (
      <div>
        {news.image
          ?
          <div style={{ padding: "0 10px 0 10px" }}>
            <img style={{ width: "100px" }} src={require(`assets/${news.image}`).default} title="" alt={news.title}/>
          </div>
          : null
        }
      </div>
    );
  };
  const renderNews = (news) => {
    return (

      <div className="row">
        <div className="row__content" style={{ display: "flex", flexDirection: "row" }}>
          {renderImage(news)}
          <div className="row__link">
            <MKTypography variant="h6" fontWeight={"light"}>
              {news.date}
            </MKTypography>
            <MKTypography variant="h6" style={{ color: "rgb(26,117,255)" }}>
              {news.title}
            </MKTypography>
          </div>

        </div>
      </div>

    );
  };


  return (
    <div>
      {props.posts.map((news) => (
        <Grid item xs={props.xs} md={props.md} sx={{ mb: 3 }} key={news.id}>
          <MKBox position="relative">
            <MKBox mt={1} ml={1} lineHeight={1}>
              {(news.detailed) ? (
                  <Link to={`/news/${news.id}`}>
                    {renderNews(news)}
                  </Link>
                )
                : renderNews(news)
              }
            </MKBox>
          </MKBox>
        </Grid>
      ))}

    </div>
  );
};

export default FetchNews;