import React from "react";

const Wrap = ({children}) => {
  return (
    <>
    {children}
    </>
  );
};

export default Wrap;