import React, { useContext, useState } from "react";
import flag_blue from "../assets/images/apartments/flag_blue.jpg";
import flag_red from "../assets/images/apartments/flag_red.jpg";
import Fancybox from "./Fancybox";
import { AuthContext } from "../context";
import axios from "axios";
import Popup from "./Popup";
import Wrap from "./Wrap";
import MKInput from "./MKInput";
import MKButton from "./MKButton";
import Grid from "@mui/material/Grid";


const TableLiterBody = (props) => {
  const floors = props.floors;
  const compareFloors = (a, b) => b.id - a.id;
  const floorsSorted = floors.sort(compareFloors);
  const { isAuth, setIsAuth, isLoaded, setIsLoaded } = useContext(AuthContext);
  const [modalActive, SetModalActive] = useState([]);
  const [value, setValue] = useState('');
  const handleChange = (event) => {
    setValue(event.target.value);
  }
  return (
<div>
      {
        floorsSorted.map((floor, index) => (
            floor.kvs.map((kv, index_2) => {
              const divID = `content${kv.id}`;
              const apartNum = `Квартира №${kv.id}`;

              return (
                <Wrap key={`${index}${kv.id}`}>
                  <Popup active={modalActive[`${index}${kv.id}`]} SetActive={SetModalActive} id={divID}>
                    {kv.inlineBlock
                      ? <div className="planirovka">
                        <div className="column">
                          <div className="column__title">Планировка квартиры</div>
                          <img src={require(`assets/${kv.img}`).default} title="" alt={apartNum}/>
                        </div>
                        {kv.imgFlooor
                          ?
                          <div className="column column2">
                            <div className="column__title">Планировка этажа</div>
                            <img src={require(`assets/${kv.imgFlooor}`).default} title="" alt={apartNum}/>
                          </div>
                          : null
                        }
                      </div>
                      : null
                    }
                    <div className="row-info-wrap">
                      {kv.price
                        ?
                        <div className="row-info">
                          <span className="row-info1">Стоимость квартиры: </span>
                          <span className="row-info2">{kv.price} руб.</span>
                        </div>
                        : null
                      }
                      <div className="row-info">
                        <span className="row-info1">Отдел продаж: </span>
                        <span className="row-info2">8-914-384-78-68, 49-10-99, <span
                          className="wordwrap">52-58-85</span></span>
                      </div>
                      <div className="row-info">
                        <span className="row-info1">E-mail: </span>
                        <span className="row-info2">aszblag@mail.ru</span>
                      </div>
                    </div>

                  </Popup>

                </Wrap>

              );
            })
        ))}




    <div>
      <div className="flag_color_wrapper">
        <div className="flag_color">
          <img src={flag_blue} width="10" height="10" alt="color_blue"/><span className="color0"> - продается</span>
        </div>
        <div className="flag_color">
          <img src={flag_red} width="10" height="10" alt="color_red"/><span
          className="color1Brighter"> - недоступно</span>
        </div>
      </div>
      <table className="apartment-table">
        <tbody>
        {
          floorsSorted.map((floor, index) => (
            <tr key={index}>
              <td className="firstSell">
                Этаж{floor.name}
              </td>
              {floor.kvs.map((kv, index_2) => {
                let colspanNum = 0;
                if (kv.colspan !== undefined) {
                  colspanNum = kv.colspan;
                }
                const divID = `content${kv.id}`;
                const apartNum = `Квартира №${kv.id}`;

                return (
                  <Wrap key={`${index}${kv.id}`}>

                    <td
                        colSpan={colspanNum}
                    >
                      <div key={index}
                           onClick={() => {
                             const mas = [];
                             mas[`${index}${kv.id}`] = true;
                             SetModalActive(mas);
                           }} >

                        {(kv.office == undefined) ? (
                            (kv.price) ?
                              <div>
                                <div className={`color${kv.status}`}>{kv.rooms}-к, {kv.size} __ №{kv.id}</div>
                                <div className={`color${kv.status}`}>{kv.price} руб.</div>
                              </div>
                              :
                              <div>
                                <div className={`color${kv.status}`}>{kv.size} _____ №{kv.id}</div>
                                <div className={`color${kv.status}`}>{kv.rooms}-к.</div>
                              </div>

                          )
                          : (
                            <div>
                              <div className={`color${kv.status}`}>{kv.size}</div>
                              <div className={`color${kv.status}`}>Офис №{kv.office}</div>
                            </div>
                          )
                        }
                      </div>

                      {isAuth
                        ?
                        <div>
                          <MKButton disabled={!isLoaded} onClick={() => {
                            const newStatus = (kv.status == 1) ? 0 : 1;
                            axios.get(`https://nsk.aszblag.ru/status_change.php?houseID=${props.house}&entrance=${props.entrance}&floor=${floor.name}&status=${newStatus}&numRow=${kv.numRow}`);
                            setIsLoaded(false);
                          }}>
                            Изменить статус
                          </MKButton>
                          <div>
                          </div>
                          <MKInput
                            variant="standard"
                            type="text"
                            label="Введите новую цену"
                            value={value}
                            onChange={handleChange} />
                          <div style={{ height: "10px", marginBottom: "1px" }}></div>
                          <MKButton type="submit" variant="gradient" color="info" disabled={!isLoaded} onClick={() => {
                            axios.get(`https://nsk.aszblag.ru/price_change.php?houseID=${props.house}&entrance=${props.entrance}&floor=${floor.name}&price="${value}"&numRow=${kv.numRow}`);
                            setIsLoaded(false);
                          }}>
                            Изменить цену
                          </MKButton>

                        </div>
                        : null


                      }

                    </td>

                  </Wrap>

                );
              })}
            </tr>
          ))}
        </tbody>
      </table>
    </div>
</div>
  );
};

export default TableLiterBody;