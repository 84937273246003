import React from "react";
import Card from "@mui/material/Card";
import Container from "@mui/material/Container";
import MKBox from "../components/MKBox";
import Grid from "@mui/material/Grid";
import DefaultFooter from "../examples/Footers/DefaultFooter";
import footerRoutes from "../footer.routes";
import DefaultNavbar from "../examples/Navbars/DefaultNavbar";
import routes from "../routes";
import MKTypography from "../components/MKTypography";
import "styles/App.css";
import FetchDocs from "../components/FetchDocs";
import BasicTabs from "../components/BasicTabs";
import docDeclarationBD from "../data/docDeclarationBD";

const DeclarationPage = () => {
  const paramTabs = [
    {
      label: "НСК",
      content: <FetchDocs company={1} bd={docDeclarationBD}/>,
    },
    {
      label: "АМУРСТРОЙЗАКАЗЧИК",
      content: <FetchDocs company={2} bd={docDeclarationBD}/>,
    },
  ];
  return (
    <>
      <DefaultNavbar
        routes={routes}
      />
      <div style={{ height: "100px" }}/>
      <Card
        sx={{
          p: 2,
          mx: { xs: 2, lg: 3 },
          mt: -8,
          mb: 4,
          boxShadow: ({ boxShadows: { xxl } }) => xxl,
        }}
      >
        <div style={{ height: "50px" }}/>

        <Container>
          <Grid container alignItems="flex-start">
            <MKTypography variant="h3" fontWeight="bold" style={{ marginBottom: "50px" }}>
              ПРОЕКТНЫЕ ДЕКЛАРАЦИИ И ИЗМЕНЕНИЯ К НИМ
            </MKTypography>
            <BasicTabs params={paramTabs}/>
          </Grid>
        </Container>

      </Card>
      <Card
        sx={{
          p: 2,
          mx: { xs: 2, lg: 3 },
          mt: -8,
          mb: 4,
          boxShadow: ({ boxShadows: { xxl } }) => xxl,
        }}
      >

        <Container>
          <MKBox component="section" pt={6} my={6}>
            <Grid container spacing={3} alignItems="center">
            </Grid>
          </MKBox>
        </Container>
      </Card>

      <MKBox pt={6} px={1} mt={6}>
        <DefaultFooter content={footerRoutes}/>
      </MKBox>
    </>
  );
};

export default DeclarationPage;