import React from "react";

const ListOffices = ({ list }) => {
  return (
    <div style={{ textAlign: "center" }}>
      <table className="list-offices">
        <tbody>
        <tr className="list-offices__row1">
          <td className="list-offices__col1">
            Объект
          </td>
          <td className="list-offices__col2">
            Этаж
          </td>
          <td className="list-offices__col3">
            Площадь, м<sup>2</sup>
          </td>
          <td className="list-offices__col4">
            Цена
          </td>
        </tr>
        {
          list.map(el =>
            <tr>
              <td className="list-offices__col4">{el.name}</td>
              <td className="list-offices__col4">{el.stage}</td>
              <td className="list-offices__col4">{el.square}</td>
              <td className="list-offices__col4">{el.price}</td>
            </tr>,
          )}
        </tbody>
      </table>
    </div>
  );
};

export default ListOffices;
