import React, { useContext, useEffect, useState } from "react";
import DefaultNavbar from "../examples/Navbars/DefaultNavbar";
import routes from "../routes";
import Card from "@mui/material/Card";
import Container from "@mui/material/Container";
import MKBox from "../components/MKBox";
import Grid from "@mui/material/Grid";
import { Link } from "react-router-dom";
import DefaultFooter from "../examples/Footers/DefaultFooter";
import footerRoutes from "../footer.routes";
import categoryObjectsBD from "../data/categoryObjectsBD";
import CategoryCard from "./LandingPages/components/CategoryCard";
import YouTube from "react-youtube";
import Marquee from "react-fast-marquee";
import Banners from "../components/Banners";
import FetchNews from "../components/FetchNews";
import BlockInfo from "../components/BlockInfo";
import { GetDataService } from "../API/GetDataService";
import Popup from "../components/Popup";
import axios from "axios";
import { AuthContext } from "../context";
import TableLiterBody from "../components/TableLiterBody";

const StartPage = () => {
  const [posts, setPosts] = useState([]);
  useEffect(()=>{
    const response = GetDataService(2, 1);
    setPosts(response);
  },[])
  const contentNews = {
    title: "Последние новости",
    titleFontSize: "h4",
    content: <FetchNews xs={12} md={12} posts={posts}/>,
  };
  const opts = {
    width: '100%',
  };

  const [constants, setConstants] = useState("");
  const [isLoaded, setIsLoaded] = useState("");
  const [runstring, setRunstring] = useState("");

  async function getData() {
    const response = await axios.get(`https://nsk.aszblag.ru/constants.php`);
    setConstants(response.data);
    setIsLoaded(true);
  }

  if (!isLoaded) {
    getData();
  }

  useEffect(() => {

    if (constants) {
      setRunstring(constants["run_string"]);
    }
  }, [constants]);


  const [modalActive, SetModalActive] = useState(false);
  return (
    <>
      <DefaultNavbar
        routes={routes}
      />

      <div style={{ height: "1px", marginBottom: "100px" }}/>
      <Card
        sx={{
          p: 2,
          mx: { xs: 2, lg: 3 },
          mt: -8,
          mb: 4,
          boxShadow: ({ boxShadows: { xxl } }) => xxl,
        }}
      >

        <Container>

          <MKBox component="section" pt={6} my={6}>

            <Grid container spacing={2} alignItems="center">

              <Marquee gradient={false} pauseOnHover={true} speed={60} style={{
                color: "#D9470D",
                fontSize: "20px",
                fontWeight: "bolder",
                lineHeight: "150%",
                textShadow: "#000000 0px 1px 1px",
              }}>
                <span>&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;</span>
                <span>{runstring}</span>
              </Marquee>
              {categoryObjectsBD.map((object) => (
                <Grid item xs={12} md={6} sx={{ mb: 2 }} key={object.id}>
                  <Link to={object.route}>
                    <CategoryCard img={object.image} name={object.title}/>
                  </Link>
                </Grid>
              ))}

            </Grid>

            <Grid container spacing={2} alignItems="center">
              <Grid item xs={12} md={6} sx={{ mb: 2 }}>
                <YouTube videoId="h7-lp_khDvY" opts={opts}/>
              </Grid>

              <Grid item xs={12} md={6} sx={{ mb: 2 }}>
                <YouTube videoId="yhsjFR2LKOg" opts={opts}/>
              </Grid>
            </Grid>

            <BlockInfo info={contentNews} mt={{ xs: 20, sm: 18, md: 4 }} mb={{ xs: 20, sm: 18, md: 2 }}/>

            <div style={{ height: "1px", marginBottom: "40px" }}/>
            <Banners/>
          </MKBox>
        </Container>
      </Card>

      <MKBox pt={6} px={1} mt={6}>
        <DefaultFooter content={footerRoutes}/>
      </MKBox>
    </>
  );
};

export default StartPage;