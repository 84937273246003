import React from "react";
import Grid from "@mui/material/Grid";
import sberLogo from "../assets/images/banners/6.jpg";
import sberLogo2 from "../assets/images/banners/logo_sber.png";
import atbLogo1 from "../assets/images/banners/banner_atb_5.jpg";
import atbLogo2 from "../assets/images/banners/banner_atb_4.jpg";
import rsbLogo from "../assets/images/banners/logo_rsb.png";
import openLogo from "../assets/images/banners/logo_open.png";
import skbLogo from "../assets/images/banners/logo_skb.png";
import vtbLogo from "../assets/images/banners/logo_vtb.png";
import gasLogo from "../assets/images/banners/logo_gas.png";
import YouTube from "react-youtube";
import MKBox from "./MKBox";
import "../styles/App.css";
import MKTypography from "./MKTypography";
import BlockInfo from "./BlockInfo";
import Container from "@mui/material/Container";
import Card from "@mui/material/Card";
import ContactForm from "./ContactForm";

const Banners = () => {
  const partners =
    {
      title: "Наши партнеры",
      titleFontSize: "h4",
      content:
        <div className="banners">
          <Grid container spacing={0} alignItems="center">
            <Grid item xs={12} md={6} sx={{ mb: 2 }}>
              <a
                href="http://sberbank.ru/amur/ru/person/credits/home/?utm_source=google.b&amp;utm_medium=context&amp;utm_term=garantiya&amp;utm_campaign=Ipoteka"
                target="_blank">
                <img src={sberLogo2} border="0"/>
              </a>
            </Grid>

            <Grid item xs={12} md={6} sx={{ mb: 2 }}>
              <a href="http://rshb.ru/natural/loans/mortgage/" target="_blank">
                <img src={rsbLogo} border="0"/>
              </a>
            </Grid>

            <Grid item xs={12} md={6} sx={{ mb: 2 }}>
              <a id="img_open" href="https://www.open.ru/ipoteka" target="_blank">
                <img src={openLogo} border="0"/>
              </a>
            </Grid>

            <Grid item xs={12} md={6} sx={{ mb: 2 }}>
              <a id="img_skb"
                 href="https://app.sovcombank.ru/ipotekanew/?utm_medium=referral&amp;utm_source=site&amp;utm_campaign=main"
                 target="_blank"><img src={skbLogo} border="0"/>
              </a>
            </Grid>

            <Grid item xs={12} md={6} sx={{ mb: 2 }}>
              <a id="img_vtb"
                 href="http://www.vtb24.ru/personal/loans/mortgage/base/new/calculator/Pages/default.aspx?geo=blagoveschensk"
                 target="_blank"><img src={vtbLogo} border="0"/>
              </a>
            </Grid>

            <Grid item xs={12} md={6} sx={{ mb: 2 }}>
              <a id="img_svb"
                 href="https://www.gazprombank.ru/personal/take_credit/mortgage/"
                 target="_blank"><img src={gasLogo} border="0"/>
              </a>
            </Grid>
          </Grid>
        </div>,

    };

  const banners =
    {
      content:
        <>
          <a href="https://www.sberbank.ru/ru/person/credits/home/buying_complete_house_daln" target="_blank">
            <img src={sberLogo} border="0" style={{ margin: "0 5px", width: "100%", maxWidth: "133px", maxHeight: "359px" }}/>
          </a>
          <img src={atbLogo1} border="0" style={{ margin: "0 5px", width: "100%", maxWidth: "359px" }}/>
          <img src={atbLogo2} border="0" style={{ margin: "0 5px", width: "100%"}}/>
        </>,
    };

  return (
    <div>
      <Container>
        <Grid container alignItems="flex-start">
          <Grid item xs={12} md={6}>
            <BlockInfo info={banners} mt={{ xs: 20, sm: 18, md: 4 }} mb={{ xs: 20, sm: 18, md: 2 }}/>
          </Grid>
          <Grid item xs={12} md={6}>
            <BlockInfo info={partners} mt={{ xs: 20, sm: 18, md: 8 }} mb={{ xs: 20, sm: 18, md: 2 }}/>
          </Grid>
        </Grid>
      </Container>
    </div>
  );
};

export default Banners;